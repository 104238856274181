export const ErrorType = {
    SUCCESS : 'success',
    ERROR : 'error',
    WARNING : 'warning'
}

export const ErrorMessage = {
    TASKMOVEMENTSUCCESS : 'Task movement has been successful.',
    TASKMOVEMENTERROR: 'Task movement failed.',
    ITEMMOVEMENTSUCCESS: "Item movement has been successful.",
    ITEMMOVEMENTERROR: "Item movement failed.",
    TASKMOVEMENTRESTRICSTIONS: 'Task selection is restricted or inapplicable for movement. It could be due to one or more of the following reasons: \n 1. If the task state is other than planned & source or target route is other than Sorting or Loading state. \n 2. If the task address is missing. \n3. If the task state is completed. \n 4. If the task type is a break. \n 5. If the task belongs to different business location. \n 6. If the previous date has been selected.',
    MAPCOLORALERT: 'Can\'t select more than 20 routes.',
    NOTASKONROUTE: 'There is no task/stop present on the selected route',
    NOROUTE: 'No route is present on this Business Location',
    REQUIREDFIELDS: 'Please fill in all required fields correctly.',
    CREATEROUTESUCCESS: 'Route creation is successful',
    NETWORKERROR: 'Network Error! Please try again.',
    PASTTIME: 'Route cannot be created with past time',
    ROUTEEXIST: 'Route already exists',
    BARCODENOTFOUND: 'Barcode Id not found',
    PBMNOTFOUND: 'PBM order id not found',
    ADDRESSNOTFOUND: 'Address could not be found',
    ADDRESSUPDATEDSUCCESS: 'Address has been updated successfully',
    ADDRESSUPDATEDFAIL: 'Address can not be updated',
    CONTAINERNOTFOUND:'Container id not found',
    OFFLINESTATUS: `There's no internet connection, please check the connection.`,
    REJECT:'Rejected pullback to unplanned pickup',
    INVALIDTIMEERROR:'End time must be greater than begin time',
    TASKSELECTIONWARNING:`Please note: task selection doesn't work with Stop View, please change the view to task view`
}

export const WarningMessage = {
    NOROUTEDATA: `There's no data present for the selected route OR there's no route selected. Please select the route`
}
