import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  constructor(private _msalService: MsalService,
    private router: Router,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let isRolesMatched: boolean = false
    let loggedInAccount: any = this._msalService.instance.getAllAccounts()[0];
    if (loggedInAccount?.idTokenClaims?.roles?.length) {
      const userRoles = [...loggedInAccount.idTokenClaims.roles];
      const allowedRoles = next?.data["roles"];
      const matchingRoles = userRoles?.filter((x: any) => allowedRoles?.includes(x));
      isRolesMatched = matchingRoles.length > 0;
      if (!isRolesMatched) {
        this.router.navigateByUrl('error');
      }
    }
    else {
      this.router.navigateByUrl('error');
    }
    return isRolesMatched;
  }
}