<p-multiSelect 
    [options]="data" 
    autoWidth="false"
    optionLabel="value"
    [(ngModel)]="selectedOption" 
    [placeholder]="placeholder"
    [style]="{ width: width + '%' }"
    [showToggleAll]="false"
    (onChange)="onTeamsSelectionChange($event)"
    selectedItemsLabel="{0} items selected"
    [resetFilterOnHide]="true"
    appendTo="body"
    >
</p-multiSelect>