import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthorizedRolesService } from '../../services/access-roles/authorized-roles.service';

@Component({
  selector: 'app-error-window',
  templateUrl: './error-window.component.html',
  styleUrls: ['./error-window.component.scss']
})
export class ErrorWindowComponent implements OnInit {
  constructor(
    public authService: MsalService
    , private router: Router
    , private authorizeRolesService: AuthorizedRolesService

  ) { }

  roles: Array<string>;
  desktopView = true;

  ngOnInit(): void {
    let loggedInAccount: any = this.authService.instance.getAllAccounts()[0];
    if (loggedInAccount?.idTokenClaims?.roles?.length) {
      this.roles = [...loggedInAccount.idTokenClaims.roles];
      if (this.authorizeRolesService.IsAuthorized(this.roles)) {
        if (this.authService.instance.getAllAccounts().length > 0) {
          this.router.navigate(['/']);
        }
      }
      else {
        this.router.navigateByUrl('error');
      }
    }
    //check for empty role for a logged-in user
    if (loggedInAccount?.idTokenClaims?.roles?.length === 0) {
      this.router.navigateByUrl('error');
    }
  }

  logout() {
    this.authService.logout();
  }

}
