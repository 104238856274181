/**
 * Constants Values used in applicaiton, ordered in Alpabatically
 */
export const AppConstants = {
    BUSINESSLOCATIONS: 'businesslocations',
    COSTCENTERS: 'costcenters',
    DAYSOFFSET: 'daysoffset',
    GETREQUEST: 'get',
    POSTREQUEST: 'post',
    DELETEREQUEST: 'delete',
    ROUTES: 'routes',
    ADDRESSPOINT:'addresses',
    AIRRECIPIENT:'legacy-id-resolver/lookup',
    IDENTITY:'identity',
    Command:'command',
    DP:'dp',
    V1:'v1',
    V4:'v4',
    GPS:'GPS',
    Address:'address',
    Vehicles : 'vehicleregistrationnumber',
    Workshifts :'workshifts',
    USER_PREFERENCES: 'user/preferences',
    SAVE_PREFERENCES: 'user/savePreferences',
    DELETE_PREFERENCES: 'user/deletePreference?preferenceId=',
    DELETE_MESSAGE: 'Are you sure you want to delete this favorite list?',
    DELETE_CONFIRMATION: 'Delete Confirmation',
    OPTIMIZATION_DETAILS: 'optimizationDetails',
    PREFERENCE: 'preferences',
    DELETE_PREFERENCE: 'deletePreference',
    USER: 'user',
    POST_PREFERENCE_USER: 'savePreferences',
}
/**
 * Messaging Constants Values used in applicaiton
 */
export const MessageConstants = {
    PULLBACKCONFIRMATION:'Are you sure you want to pullback to unplanned pickup?',
    HEADER:'Confirmation'
}

export const AccessRoles = {
    GLOBALADMINISTRATOR: 'GlobalAdministrator',
    ADMINISTRATOR: 'Administrator',
    MANAGER: 'Manager'
}

