<div id="home-wrapper">
    <!-- app-header -->
    <p-confirmDialog></p-confirmDialog>
    <p-toast  position="top-center" [preventOpenDuplicates]="true">
        <ng-template let-message pTemplate="message">
            <div class="ui-toast-message-text-content">
                <div class="ui-toast-detail" [innerHTML]="message.detail"></div>
            </div>
        </ng-template>
    </p-toast>
    <app-actionbar (appOptionsStatus)="appOptionsStatus($event)" [hideAppOptions]="hideAppOptions"></app-actionbar>
    <div #content  class="wrapper">
        <!-- overlay here -->
        <app-side-menu [hideAppOptions]="hideAppOptions" (appOptionsStatus)="appOptionsStatus($event)"></app-side-menu>
        <div id="content" class="content">
            <div class="container-fluid p-0 h-100">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <app-loader></app-loader>
</div>