import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedRolesService {

    
  IsAuthorized(roles: Array<string>): boolean {
    return this.IsManager(roles) || this.isAdmin(roles);
  }

  IsManager(roles: Array<string>): boolean {
    return roles.includes('Manager');
  }

  isAdmin(roles: Array<string>): boolean {
    return roles.includes('Administrator') || roles.includes('GlobalAdministrator');
  }

}