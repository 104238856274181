import { Injectable } from '@angular/core';
import { SearchComponent, TBTabName } from '../../entity/enums';
import { ConfirmationService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private confirmationService: ConfirmationService){
  }

  filterByValue(array: any[], searchText: any) {
    return array.filter((data) => this.checkValue(data,searchText));
}

 checkValue(value: unknown, filterValue: string):any {
  if (typeof value === 'string') {
    return value.toLowerCase().includes(filterValue.toLowerCase());
  } else if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
    if (Array.isArray(value)) {
      return value.some((v) => v != undefined?this.checkValue(v, filterValue) :null);
    } else {
      return Object.values(value).some((v) => this.checkValue(v, filterValue));
    }
  } else {
    return false;
  }
}

  customizeSearchFields(dataCollection: any[], searchForComponent: any, tabStateLabel='') {
    let fieldsCollection: any;
    switch (searchForComponent) {
      case SearchComponent.containerDetails:
        fieldsCollection = dataCollection.map(m => (
          {
            product_code: m.product_code
            , item_id: m.item_id
            , task_point_name: m.task_point_name
            , pn_item_id: m.pn_item_id
            , additional_service_codes: m.additional_service_codes
            , route_label: m.route_label
            , stop_name: m.stop_name
            , bl_name: m.bl_name
          }));
        break;
      case SearchComponent.routeDetails:
        fieldsCollection = dataCollection.map(m => (
          {
            stopName: m.stopName
            , stop_id: m.stop_id
            , taskPointName: m.tasks.map((t:any) => t.taskPointName)
            , pn_item_id: m.tasks.map((t:any) => t.items.map((i:any) => i.pn_item_id))
            , pamId: m.tasks.map((t:any) => (t.pamId).toString())
            , taskId: m.tasks.map((t:any) => t.taskId)
          }));
          break;
      case SearchComponent.routeSummary:
        fieldsCollection = dataCollection.map(m => (
          {
            stopName: m.stopName
            , stop_id: m.stop_id
            , taskPointName: m.tasks.map((t:any) => t.taskPointName)
            , pn_item_id: m.tasks.map((t:any) => t.items.map((i:any) => i.pn_item_id))
          }));
        break;
      case SearchComponent.containerInfo:
        fieldsCollection = dataCollection.map(m => (
          {
            item_container_id: m.item_container_id
            , barcodes: m.barcodes[0]
          }));
        break;
      case SearchComponent.taskBank:
        if(tabStateLabel === TBTabName.Unplannedpickup &&  TBTabName.PlannedPBM) {
          fieldsCollection = dataCollection.map(collection => {
            return {
              id: collection.id,
              task_point_name: collection.task_point_name,
              address: collection.address,
              pn_item_id: collection.ItemDetails?.map((f:any) => f.pn_item_id) ?? '',
              pbm_order_id: collection.pbm_order_id,
              bl_id: collection.bl_id,
              bl_name: collection.bl_name,
              driver_instruction: collection.driver_instruction,
              delivery_point_area_name: collection.delivery_point_area_name,
              vehicle_type: collection.vehicle_type,
              task_state: collection.task_state,
              manager_instruction: collection.manager_instruction,
              task_type: collection.task_type
            }
          })
        } else {
          fieldsCollection = dataCollection.map(collection => {
            return {
              id: collection.id,
              task_point_name: collection.task_point_name,
              task_type: collection.task_type,
              address: collection.address,
              pbm_order_id  : collection.pbm_order_id ,
              route_name: collection.route_name,
              delivery_point_area_name: collection.delivery_point_area_name,
              pn_item_id: collection.ItemDetails?.map((f:any) => f.pn_item_id) ?? [],
              task_state: collection.task_state,
            }
          })
        }
        break;
      case SearchComponent.barcode: 
      fieldsCollection = dataCollection.map(collection => {
        return {
          id: collection.id,
          pn_item_id: collection.ItemDetails?.map((f:any) => f.pn_item_id) ?? [],
        }
      })
        break;
      case SearchComponent.consignee: 
      fieldsCollection = dataCollection.map(collection => {
        return {
          id: collection.id,
          consignee_name: collection.ItemDetails?.map((t: any) => (t?.consignee_name ?? '').toString()) ?? []
        }
      })
        break;
      case SearchComponent.productcode: 
      fieldsCollection = dataCollection.map(collection => {
        return {
          id: collection.id,
          product_code: collection?.ItemDetails?.map((t: any) => (t?.product_code ?? '').toString()) ?? []
        }
      })
      break;
      default:
        break;
    }
    return fieldsCollection;
  }

  dateFormatter(inputDate:Date){
    return new Date(new Date(inputDate).toLocaleString('sv-SE', {
      timeZone: 'Europe/Stockholm',
    }));
  }

  formatDate(date:Date) {
    // Extracting date components
    const dateFormat = this.dateFormatter(date)
    const month = String(dateFormat.getMonth() + 1).padStart(2, '0');
    const day = String(dateFormat.getDate()).padStart(2, '0');
    const year = dateFormat.getFullYear();
  
    // Extracting time components
  
    // Format the date and time
    const formattedDateTime = `${month}/${day}/${year}`;
  
    return formattedDateTime;
  }

  /**
   * 
   * @param dateArray list of date array. 
   * @param type is either min or max (i.e whether you want to find min value and max value)
   * @returns return min or max Date  based on your type.
   */
  getMinMaxDate(dateArray:any, type:any) {
    if(type === 'max') {
      return new Date(Math.max.apply(null, dateArray));
    }
    if(type === 'min') {
      return new Date(Math.min.apply(null, dateArray));
    }

    return '';
  }

  /**
   * To provides a reusable way to display a confirmation dialog
   * using primeng, it accepts dynamic input for the message, header, and the 
   * action to be perform on accept and reject the confirmation
   * 
   * @param message To pass the dynamic message shown in confirm dialog
   * @param header To pass the dynamic Header title shown in confirm dialog
   * @param onAccept a callback method to be executed on confirm action 
   * @param onReject a callback method to be executed on onReject action
   */
  showConfirmationAlert(message:string, header:string, onAccept:() => void, onReject?:() => void) {
    this.confirmationService.confirm({
      header: header,
      message: message,
      rejectButtonStyleClass: 'btn btn-primary me-2 ',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'btn btn-primary',
      rejectLabel:'Cancel',
      acceptLabel:'Confirm',
      accept: () => {
        onAccept();
      },
      reject: () => {
        if(onReject){
          onReject();
        }
      }
    } );
  }
}
