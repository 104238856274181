<div class="whiteOverlay" [hidden]="showOverlay === false" >
    <div class="loader-background">
        <div class="loader">
            <span class="spinner-label">Please wait while data is loading</span>
            <svg class="progress-spinner">
                <circle 
                class="progress-spinner-circle" 
                cx="25" 
                cy="25"
                r="20"
                fill="none"
               stroke-width="8">
            </circle>
            </svg>
        </div>
    </div>
</div>