import { Component } from '@angular/core';
import { DataAccessService } from '../../services/data-access/data-access.service';
import { DataRepositoryService } from '../../services/data-repository/data-repository.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FavBloms } from '../../models/favourite';
import { MessageService } from 'primeng/api';
import { AppConstants } from '../../entity/constants';
import { ConnectionService } from '../../services/connection/connection.service';
import { CommandType, RouteType } from '../../entity/enums';
import { ErrorMessage, ErrorType } from '../../entity/error.const';
import { HttpStatusCode } from '@angular/common/http';
import { CreateRoute } from '../../entity/utility-interfaces';
import { Guid } from 'guid-typescript';
import { CostCenter, TeamResponse, Vehicle, VehicleResponse, Workshift, WorkshiftResponse } from '../../models/createRoute-details';
import { DropdownChangeEvent, DropdownFilterEvent } from 'primeng/dropdown';


@Component({
  selector: 'app-create-route',
  templateUrl: './create-route.component.html',
  styleUrls: ['./create-route.component.scss']
})
export class CreateRouteComponent {
  routeTypeOptions: { label: string, value: RouteType }[] = [];
  workshiftList: Workshift[] = [];
  vehicleRegNo: Vehicle[] = [];
  teamList: CostCenter[] = [];
  displayDialog: boolean = false;
  formPopupVisible: boolean = false;
  routeName: string;
  date: Date = new Date();
  businessLocationsList: FavBloms[] = [];
  tooltipName: string = 'Create Route';
  routeForm: FormGroup;
  minimumDate = new Date();
  createRouteData: CreateRoute[] = [];

  showAddWorkShiftFlag: boolean = false;
  showAddVehicleFlag: boolean = false;

  workshiftFilterText: string = '';
  vehicleFilterText: string = ''; 

  workShiftName: string = '';
  vehicle_reg_no: string = '';

  constructor(
    public dataAccessService: DataAccessService,
    private formBuilder: FormBuilder,
    private dataRepositoryService: DataRepositoryService,
    private messageService: MessageService,
    private connectionService: ConnectionService
  ) {
    this.routeForm = this.formBuilder.group({
      businessLocation: new FormControl('', [Validators.required]),
      routeName: new FormControl('', Validators.required),
      date: new FormControl({value : new Date(),disabled:true}, Validators.required),
      begin: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required),
      team: new FormControl(''),
      vehicle: new FormControl(''),
      workshift: new FormControl(''),
      routetype: new FormControl('')
    });
    this.routeTypeOptions = this.getRouteType();
  }


  /**
   * Method show popup
   */
  showRouteDialog() {
    this.formPopupVisible = true;
    this.routeForm.reset();
    this.getBusinessLocation();
    this.routeForm.controls['date'].setValue(new Date());
  }
  /**
   * Clicking on save button it will call command api
   * It check validations and based on that show error messages
   */
  save() {
    if (this.routeForm.invalid) {
      this.routeForm.markAllAsTouched();
      this.messageService.add({ severity: ErrorType.ERROR, summary: '', detail: ErrorMessage.REQUIREDFIELDS });
      return;
    }
    const begin = this.routeForm.get('begin')?.value ?? null;
    const date = this.routeForm.get('date')?.value ?? null;
    if (begin && (begin.getTime() < this.minimumDate.getTime())) {
      if (date === this.minimumDate) {
        this.messageService.add({ severity: ErrorType.ERROR, summary: '', detail: ErrorMessage.PASTTIME });
        return;
      }
    }
   
    const timeWindow = this.createTimeWindow();
    const uuidpattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    this.createRouteData = [{
      id: Object.values(Guid.create())[0],
      business_location_id: this.routeForm.value.businessLocation,
      route_name: this.routeForm.value.routeName,
      route_state: "Sorting",
      source: "DP",
      command_type: CommandType.CreateRouteCommand,
      time_window: timeWindow,
      user_id: this.dataRepositoryService?.loggedInUser?.username ?? '',
      vehicle_id: uuidpattern.test(this.routeForm.get('vehicle')?.value) ? this.routeForm.get('vehicle')?.value : '',
      route_type:this.routeForm.get('routetype')?.value ?? '',
      workshift_id: uuidpattern.test(this.routeForm.get('workshift')?.value) ? this.routeForm.get('workshift')?.value : '',
      cost_center_id:this.routeForm.get('team')?.value ?? '',
      vehicle_reg_no: uuidpattern.test(this.routeForm.get('vehicle')?.value) ? '' : this.vehicle_reg_no,
      workshift_name: uuidpattern.test(this.routeForm.get('workshift')?.value) ? '' : this.workShiftName
    }]
    this.connectionService.httpReq(
      AppConstants.POSTREQUEST
      , AppConstants.Command
      , AppConstants.DP
      , this.createRouteData
    ).subscribe({
      next: (resp: any) => {
        this.dataAccessService.getRouteInfoDeltaOnce();
        this.formPopupVisible = false;
        this.messageService.add({ severity: ErrorType.SUCCESS, summary: '', detail: ErrorMessage.CREATEROUTESUCCESS });
      }, error: (err: any) => {
        if (err.status === HttpStatusCode.BadRequest) {
          this.messageService.add({ severity: ErrorType.ERROR, summary: '', detail: ErrorMessage.ROUTEEXIST });
        } else {
          this.messageService.add({ severity: ErrorType.ERROR, summary: '', detail: ErrorMessage.NETWORKERROR });
        }
      }
    })
    this.formPopupVisible = false;
  }

  getBussinessLocationData() {
    this.dataAccessService.getBLData().subscribe(res => {
      this.businessLocationsList = [...res];
    });
  }
  /**
   * Method use to get business location
   */
  getBusinessLocation() {
    if (this.dataRepositoryService.businessLocationListMap.size) {
      this.businessLocationsList = [...this.dataRepositoryService.businessLocationListMap.values()].map(data => {
        return {
          id: data.business_location_id,
          value: data.name
        }
      });
    } else {
      this.getBussinessLocationData();
    }
  }

  get formValue() {
    return this.routeForm.controls;
  }

  /**
   * Enable overnight icon when end time is less than begin time
   */
  onTimeChange() {
    const begin = this.routeForm.get('begin')?.value ?? null;
    const end = this.routeForm.get('end')?.value ?? null;
   
    if (begin && end) {
      const beginTime = new Date(begin).setSeconds(0, 0);
      const endTime = new Date(end).setSeconds(0, 0);
   
      if (endTime <= beginTime) {
        this.messageService.add({
          severity: ErrorType.ERROR,
          summary: '',
          detail: ErrorMessage.INVALIDTIMEERROR,
        });
        this.routeForm.get('end')?.setErrors({ 'invalidEndTime': true });
      } else {
       this.routeForm.get('end')?.setErrors(null);
      }
   
    }
  }

  createTimeWindow() {
    const begin = this.routeForm.get('begin')?.value ?? null;
    const end = this.routeForm.get('end')?.value ?? null;
    const date = this.routeForm.get('date')?.value ?? null;
    return {
      begin: this.convertToEpoch(date, begin) ?? '',
      end: this.convertToEpoch(date, end) ?? ''
    }
  }
  /**
   * common method to covert time into epoch
   */
  convertToEpoch(date: Date, time: any) {
    if (!date && !time) {
      return '';
    }
    return new Date(date).setHours(new Date(time).getHours(), new Date(time).getMinutes(), new Date(time).getSeconds()).toString()
  }

 /**
  * Method use to get Teams
  */
 
  getTeam(blid: string,dateOffset:number) {
    this.teamList = [];
   
    this.dataAccessService.getTeamsData(blid,dateOffset).subscribe({
      next: (response: TeamResponse) => {
        if (response && Array.isArray(response.cost_centers)) {
          this.teamList = response?.cost_centers
        }
      },
    });
  }

  /**
   * Method use to get vehicle Registration Number
   */
  getVehicle(blid: string) {
    this.vehicleRegNo = [];
    this.dataAccessService.getVehicleData(blid).subscribe({
      next: (response: VehicleResponse) => {
        this.vehicleRegNo = response?.vehicles;
      }
    })
  }

  /**
     * Method use to get workshift id
     */
  getWorkshiftDetails(blid: string,dateOffset:number) {
    this.dataAccessService.getWorkshiftData(blid,dateOffset).subscribe({
      next: (response: WorkshiftResponse) => {
        this.workshiftList = response?.workshifts;
      }
    });
  }

  getRouteType() {
    return [
      { label: 'Parcel Route', value: RouteType.Parcel },
      { label: 'Thick Mail Route', value: RouteType.ThickMailRoute },
      { label: 'Thin Mail Route', value: RouteType.ThinMailRoute },
      { label: 'Manual Route', value: RouteType.ManualRoute }
    ]
  }

  /**
   * Reset form controls and fetches the updated team,workshift and vehicle data based on the selected BL 
   */

  OnBLSelection(value: string) {
    const dateOffset = 0 ;
    this.showAddWorkShiftFlag= false;
    this.showAddVehicleFlag = false;
    this.getTeam(value,dateOffset)
    this.getWorkshiftDetails(value,dateOffset);
    this.getVehicle(value);
    this.resetValues();
 }
  /** 
   * Reset the form control and clears the team , vehicle and workshift data
   */
  resetValues() {
    this.routeForm.controls['team'].setValue('');
    this.routeForm.controls['vehicle'].setValue('');
    this.routeForm.controls['workshift'].setValue('');
    this.routeForm.controls['routetype'].setValue('');
    this.teamList = [];
    this.vehicleRegNo = [];
    this.workshiftList = [];
    this.showAddWorkShiftFlag= false;
    this.showAddVehicleFlag= false;
    this.vehicleFilterText = '';
    this.vehicle_reg_no = '';
    this.workShiftName = '';
    this.workshiftFilterText = '';
  }

  /**
   * To handle Filter event for the workshift
   * @param $event 
   */
  onWorkShiftFilter($event:DropdownFilterEvent) {
    if(this.routeForm.controls['businessLocation'].value) {
      this.workshiftFilterText = $event.filter;
      const pattern:any = /^\d{6}$|^\d{5}[A-Z]$/;
      this.showAddWorkShiftFlag = pattern.test(this.workshiftFilterText) && !this.workshiftList?.some(opt => opt.workshift_name === this.workshiftFilterText)
    }
  }

  /**
   * To handle Filter event for the Vehicle
   * @param $event 
   */
  onVechileFilter($event:DropdownFilterEvent) {
    if(this.routeForm.controls['businessLocation'].value) {
      this.vehicleFilterText = $event.filter;
      const pattern:any = /^[A-Z]{3}(\d{3}|\d{2}[A-Z])$/ ;
      this.showAddVehicleFlag = pattern.test(this.vehicleFilterText) && !this.vehicleRegNo?.some(opt => opt.reg_no === this.vehicleFilterText)
    }
  }

  /**
   * To add the new value in the Workshift Dropdown
   */
  handleAddWorkshift() {
    this.workshiftList.push({
      id:this.workshiftFilterText,
      workshift_id:this.workshiftFilterText,
      workshift_name: this.workshiftFilterText
    });
    this.routeForm.controls['workshift'].setValue(this.workshiftFilterText);
    this.workShiftName = this.workshiftFilterText;
    this.workshiftFilterText = '';
    this.showAddWorkShiftFlag = false;
  }

  /**
   * To add the new value in the vehicle Dropdown
   */
  handleAddVehicle() {
    this.vehicleRegNo.push({
      id:this.vehicleFilterText,
      reg_no:this.vehicleFilterText,
    });
    this.routeForm.controls['vehicle'].setValue(this.vehicleFilterText);
    this.vehicle_reg_no = this.vehicleFilterText;
    this.vehicleFilterText = '';
    this.showAddVehicleFlag = false;
  }

  /**
   * Change Event for the WorkShift Dropdown
   * @param $event 
   */
  handleWorkshiftChange($event:DropdownChangeEvent) {
    this.workShiftName = $event?.value??''
  }

  /**
   * Change Event for the Vehicle Dropdown
   * @param $event 
   */
  handleVehicleChange($event:DropdownChangeEvent) {
    this.vehicle_reg_no = $event?.value??''
  }
}
