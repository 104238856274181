export class LookupConstant {
    static readonly  productCodes = {
         // NORDIC product codes
         12: "NORDIC - PostNord Return Pickup",
         17: "NORDIC - MyPackHome",
         18: "NORDIC - PostNord Parcel",
         19: "NORDIC - MyPack Collect",
         20: "NORDIC - PostNord Return Pickup",
         24: "NORDIC - PostNord Return Drop Off",
         48: "NORDIC - InNight",
         49: "NORDIC - InNight Reverse",
         52: "NORDIC - Pallet",
         83: "NORDIC - Pallet",
         85: "NORDIC - Part Loads",
         95: "NORDIC - Parcel Post Home (Internal PostNord use only)",
         
         // Local DK Product codes
         1: "DK - DPD Classic",
         '01': "DK - DPD Classic",
         4: "DK - Tracked Letters",
         '04': "DK - Tracked Letters",
         // 11: "DK - Customer Return Pick up",
         // 30: "DK - Overlabling af udlandspakker",
         40: "DK - Private Priority",
         43: "PNL same day",
         44: "DK - Untracked Letters",
         50: "DK - Postpakker Udland C - C (International)",
         55: "DK - Postpakker Udland Economy",
         67: "DK - Return Pick up Service Logistic",
         69: "DK - Last Mile",
         89: "DK - PostNord Food",
         90: "DK - MyPack Same Day",
         97: "DK - Parcel Post Collect (Internal PostNord use only)",
         
         // Local SE products
         14: "SE - PostNord Parcel 12",
         15: "SE - PostNord Parcel",
         21: "SE - Företagspaket Ekonomi Förbet.",
         25: "SE - Postpaket (out of scope)", // out of scope for NDM 4.0
         27: "SE - Postpaket kontantbetalda", 
         28: "SE - SverigePaket (out of scope)", // out of scope for NDM 4.0
         31: "SE - PostNord Parcel 09",
         32: "SE - Mypack Home",
         35: "SE - Företagspaket 09.00 (Förbet.) (out of scope)", // out of scope for NDM 4.0
         38: "SE - Kartong med porto / SverigePaket",
         42: "SE - Expresspaket",
         47: "SE - EMS International Express",
         51: "SE - Företagsparket Comeback",
         57: "SE - Företagspaket special",
         59: "SE - Retail Delivery",
         76: "SE - Extern produkt TNT",
         77: "SE - Extern produkt FedEx",
         82: "SE - Lokal Åkeritjänst - Paket",
         88: "SE - DPD utr DPD",
         91: "SE - International Parcel",
         92: "SE - Utrikes Ekonomipaket",
         93: "SE - eCIP Collect",
         94: "SE - eCIP Home",
         96: "SE - eCIP Return",
         98: "SE - Import-EPG",
         
         // LETTER products - from ORC/ Machine sorted
         312100: "DK - Q Brev Maskinelle",
         312300: "DK - Q Brev Manuelle breve",
         313300: "DK - Q Maxi Forsendelser",
         332800: "DK - Basis Brev",
         333300: "DK - Basis Maxi Forsendelser",
         342300: "DK - Basisforsendelser til Q ruter",
         352300: "DK - Q forsendelser til Basisruter",
         610300: "DK - Dagblade",
         714300: "DK - Pakke",
         912300: "DK - Q REK Brev",
         913300: "DK - Q REK Maxi Forsendelser",
         932300: "DK - Basis REK Brev",
         933300: "DK - Basis REK Maxi",
 
         // SE-Mail products
         11: "Varubrev Ekonomi",
         30: "MyPack Home Small",
         75: "Varubrev 1:a-klass",
         78: "Varubrev Klimatekonomisk",
         79: "Varubrev Ekonomi",
         86: "Varubrev 1:a-klass",
         87: "Varubrev Retur",
         AF: "Brevpostförskott",
         AJ: "Skicka Hem",
         IE: "EMS",
         IB: "Importklump",
         IK: "Kina REK",
         IR: "REK från utlandet",
         IV: "Värde från utlandet",
         IX: "Expressbrev import",
         LX: "Svenska Expressbrev",
         RA: "Rekkuvrt inrikes",
         RF: "Frimärksförsändelser",
         RK: "REK retur",
         RL: "REK retur",
         RP: "Valsedlar",
         RR: "REK",
         VS: "Värde Skjutvapen",
         VV: "Värde",
     };
     
     static readonly  additionalServiceCodes = {
         // NORDIC service codes
         "A1": "NORDIC - Cash on Delivery",
         "A2": "NORDIC - Notification by Letter",
         "A3": "NORDIC - Notification by SMS",
         "A4": "NORDIC - Notification by E-mail",
         "A5": "NORDIC - Insurance",
         "A6": "NORDIC - Delivery without POD",
         "A7": "NORDIC - Optional Service Point",
         "A8": "NORDIC - Dangerous Goods LQ",
         "A9": "NORDIC - Notification by Phone",
         "B1": "NORDIC - Temperature",
         "B2": "NORDIC - Private receiver",
         "B3": "NORDIC - Warranty Return",
         "B4": "NORDIC - Return Wrapping",
         "B5": "NORDIC - Return of Goods",
         "B6": "NORDIC - Carry-in Heavy",
         "B7": "NORDIC - Carry-In",
         "B8": "NORDIC - Driver Notification",
         "B9": "NORDIC - Dangerous Goods",
         "C1": "NORDIC - Recipient Freight",
         "C2": "NORDIC - Purchase On Invoice", // C3 do not exist in the specification
         "C4": "NORDIC - ID Check",
         "C5": "NORDIC - Delivery Notification",
         "C6": "NORDIC - Delivery Confirmation",
         "C7": "NORDIC - FlexChange",
         "C8": "NORDIC - Over Size",
         "C9": "NORDIC - Over Weight",
         "D1": "NORDIC - Return to Sender",
         "D2": "NORDIC - Signature Assurance",
         "D3": "NORDIC - Delivery Advice",
         "D4": "NORDIC - Locked Space",
         "D5": "NORDIC - Private Receiver",
         "D6": "NORDIC - Before 9",
         "D7": "NORDIC - Before 12",
         "D8": "NORDIC - Age check",
         "D9": "NORDIC - Climate Compensation",
         "E1": "NORDIC - Tyres",
         "E2": "NORDIC - Carry-Out",
         "E3": "NORDIC - Return Pickup Private",
         "E4": "NORDIC - Collect in Store",
         "E5": "NORDIC - Collect in Store – Label only",
         "E6": "NORDIC - Pickup Confirmation",
         "E7": "NORDIC - Pickup Handover",
         "E8": "NORDIC - SplitShipment",
         "E9": "NORDIC - Collection Request",
         "H1": "NORDIC - MyPack Box",
         "L5": "SE - Carry In",
         "L6": "SE - Off Packaging",
         "Q1": "NORDIC - Time Agreed",
         "F1": "NORDIC - SameDay",
         "N1": "NORDIC - Two delivery attempts",
         "N3": "NORDIC - No Service Point delivery",
         "W1": "NORDIC - Weight over 20kg",
         // Local DK service codes
         "2": "DK - Volume",
         "02": "DK - Volume",
         "3": "DK - Goods Return",
         "03": "DK - Goods Return",
         "4": "DK - Rekommanderet", //??
         "04": "DK - Rekommanderet", //??
         "5": "DK - Insured (amount indicated in MOA segment)",
         "05": "DK - Insured (amount indicated in MOA segment)",
         "6": "DK - Letgodskvittering uds. af PDK", // ??
         "06": "DK - Letgodskvittering uds. af PDK", // ??
         "7": "DK - Economy to FO & GL",
         "07": "DK - Economy to FO & GL",
         "8": "DK - Oversize - L over 120 cm",
         "08": "DK - Oversize - L over 120 cm",
         "9": "DK - Notification by telephone",
         "09": "DK - Notification by telephone",
         "10": "DK - Planned pickup",
         "11": "DK - Customer return pickup",
         "12": "DK - Customer Return Pickup", //??
         "13": "DK - Returpakke forsøgt afh.", //??
         "14": "DK - Bulk",
         "15": "DK - Genudbringning af gods",//??
         "16": "DK - Iland addition",
         "17": "DK - MyPack Home", //??
         "18": "DK - PostNord Parcel", //??
         "19": "DK - MyPack Collect",
         "21": "DK - Immediate Return", //??
         "22": "DK - Green consignment",
         "23": "DK - Tilbagesendte pakker",
         "24": "DK - Customer Return Drop Off", //??
         "26": "DK - ID check",
         "27": "DK - COD form printed out by Post Danmark (according to agreeement with Post Danmark only)",
         "28": "DK - Pallet Exchange",
         "29": "DK - Overall delivery (does not apply to import)",
         "30": "DK - Request for immediate return of COD item",
         "31": "DK - Delivery before 10",
         "32": "DK - Ikke maskinsorterbar",
         "33": "DK - Contract delivery of bar codes (does not apply to import)",
         "34": "DK - Udveksling ml. IPS og GTT",
         "35": "DK - Request for immediate return of COD items",
         "36": "DK - Hazardous contents",
         "37": "DK - Secured delivery (only with special agreement)",
         "38": "DK - Pakke uden omdeling", //??
         "39": "DK - Return parcel service",
         "40": "DK - Signature guarantee",
         "41": "DK - Flexible delivery",
         "42": "DK - Udveksling ml. IPS og GTT", //??
         "43": "DK - Mail matter (does not apply to import) Z46 Saturday Delivery",
         "45": "DK - Privatpakker",
         "46": "DK - +20 kg Collect to Home",
         "47": "DK - E-cip fra udlandet", //??
         "48": "DK - Sms - notification",
         "49": "DK - Sender SMS notification",
         "50": "DK - Carry in",
         "51": "DK - Alcohol, age control of recipient Only for use with special agreement",
         "52": "DK - Pallet", //??
         "53": "DK - Home delivery",
         "54": "DK - Tung Pakke",
         "55": "DK - Second delivery (paid by sender)",
         "56": "DK - Time specific delivery, before 9",
         "57": "DK - Notification by letter to receiver",
         "58": "DK - Dangerous goods in limited quantities",
         "59": "DK - Air (For FO and GL in combination with product code Z19)",
         "60": "DK - Transport Insurance Europe",
         "61": "DK - Transport Insurance other countries",
         "62": "DK - Transport Insurance DK",
         "63": "DK - MyPack with delivery (use with SMS or email notification)",
         "64": "DK - Uanbringelig - Udland", //??
         "65": "DK - Private receiver",
         "66": "DK - Time specific delivery, before 12",
         "69": "DK - Domestic Air - Grønland", //??
         "70": "DK - Paller", //??
         "72": "DK - 1/2 Paller",//??
         "73": "DK - 1/4 Paller",//??
         "74": "DK - Pallet Oversize (does not apply to import)",
         "75": "DK - Pallet (unwrapping and delivery of the items) (does not apply to import)",
         "76": "DK - Gods Retur",
         "77": "DK - Delivery to optional service point",
         "78": "DK - Special palle",//??
         "79": "DK - Vasket til privat modtager",
         "80": "DK - Afsender SMS",
         "81": "DK - E-mail -notification",
         "82": "DK - Delivery to shop (Click and Collect)",
         "83": "DK - Stykgods", //??
         "84": "DK - Collect in store",
         "85": "DK - Partloads",
         "86": "DK - Erhvervsmodtager",
         "87": "DK - EDI-data mangler på forsendelse",
         "88": "DK - Told og Skat", //??
         "89": "DK - Service Logistik",//??
         "90": "DK - MyPack Same Day",//??
         "91": "DK - Særligt afkast",//??
         "92": "DK - Indført på bakke 1",//??
         "93": "DK - Indført på bakke 2",//??
         "94": "DK - Time slot delivery",
         "95": "DK - Postpakke Home",
         "96": "DK - eCip return",//??
         "97": "DK - Postpakke Collect",//??
         "98": "DK - Flexchange Postshop",//??
         "99": "DK - Dæk",//??
         "105": "Private person specified"
     };
 
     static readonly  GTTEventCodes = {
         "OBK": "Omdelt i brevkasse",
         "SUD": "Særlig udkørsel",
         "NAN": "Nabo leveret",
         "FLX": "Flex leveret",
         "MFX": "Modtager flex",
         "OMV": "Omdelt virksomhed",
         "OMP": "Omdelt privat",
         "STM": "Udleveret store modtagere",
         "RMN": "Retur modtagelse nægtet",
         "RUA": "Retur ubekendt efter adressen", 
         "ANM": "Anmeldt",
         "EFO": "Expres forsøgt omdelt",
         "ASF": "Afsenderfejl",
         "FEJ": "Fejlsendt",
         "FEL": "Fejldirigeret",
         "FAF": "Fejl afleveret",
         "ANA": "Ankomstsorteret aftenomdeling",
         "ANO": "Ankomstsorteret omdelingsliste",
         "VEB": "Videresendt ekstraordinær befordring",
         "VOB": "Videresendt ordinær befordring",
         "BOK": "Boksudleveret",
         "ANB": "Anmeldt i boks",
         "FTA": "Frataget til afhentning",
         "TAA": "Tilbageholdt efter aftale med afsender",
         "TAM": "Tilbageholdt efter aftale med modtager",
         "UFO": "Under forespørgsel",
         "RPA": "Returpakke afhentet",
         "RFA": "Returforsendelse forsøgt afhentet",
         "AFK": "Afhentet kontraktkørsel",
         "RLK": "Returneret tilbagekaldt",
         "AFE": "Afhentet erhvervsindlevering",
         "MTB": "Modtaget til befordring",
         "OME": "Omekspederet",
         "URB": "Under reklamations behandling rest",
         "REE": "Reemballeret",
         "RIA": "Retur ikke afhentet",
         "OEO": "Overliggende efter omdeling",
         "OVR": "Overliggende",
         "IER": "Indgået efter rest",
         "PTP": "Pakke til Groupage",
         "RLA": "Fremsendt Storkunde",
         "REL": "Relabel",
         "DES": "Destrueret",
         "FRS": "Forkert rutesortering",
         "AFU": "Afgående udland",
         "UTB": "Udtaget Toldbehandling",
         "UDL": "Ankommet Terminal/Hub",
         "EML": "EML (Kundeaftale)",
         "FPC": "Forsinket postcenter",
         "AAP": "Aftal afhentning + 20 pakke",
         "SPH": "Spredescan Håndsorteret",
         "UTL": "Ude Til Levering",
         "VRP": "Videresendt returposten",
         "LLR": "Løs label registerering",
         "TEM": "Tom emballage",
         "RAF": "Retur afsender",
         "RFD": "Retur fra Distribution"
     };
 
     static readonly  HCPEventCodes:any = {
         0: "None",
         1: "Pickup",
         2: "Hand over To Postnord",
         3: "No Pickup",
         10: "Arrival",
         11: "Unload",
         12: "Register",
         13: "Destination Sorted",
         14: "Storage",
         15: "Release from storage",
         16: "Load To Vehicle/Container For Transport",
         17: "Departure",
         18: "Out for delivery",
         19: "Sort For Distribution",
         22: "Basic Registration",
         24: "Rural Postal Handling, Acknowledged PK",
         30: "Deliver To Service Point",
         31: "No delivery",
         32: "Delivery",
         40: "Return",
         50: "On Route",
         215: "Handled by Retail App"
     };
     
     static readonly  HCPReasonCodes:any = {
         0: 'None',
         1: "Capacity shortage production delayed",
         2: "No Access To Pick Up Location",
         3: "Customer not aware of Pickup assignment",
         4: "Damaged - Movement Stopped",
         5: "Item stopped, Incorrect packaging",
         7: "No goods available for Pickup at senders stated address",
         9: "Registered damage on packaging",
         10: "Sender not available",
         11: "Content is missing in item",
         14: "Damaged packaging repaired",
         24: "Goods Stored - Agreement With Sender",
         29: "Movement Stopped - Dangerous Goods - Awaiting Instructions From Sender",
         32: "sorted to wrong location",
         33: "Timed Out - Awaiting Instructions From Sender",
         35: "Destroyed - Agreement/Content/Prohibited Content",
         37: "Extraordinary Delivery Performed By Adhoc Courier",
         39: "Delivery Not Possible - Locked Entrance",
         42: "Incomplete incorrect missing EDI",
         43: "ID check failed",
         44: "Parcel exceeds servicepoint weight limit",
         46: "Recipient Not Available for Delivery",
         47: "Refused by receiver",
         48: "Refused by Receiver - Damage",
         51: "Parcel locker full",
         52: "Express delivery attempt unsuccessful",
         53: "Additional service not performed",
         54: "Damage accepted by receiver",
         55: "Delivered to third party with notification of delivery",
         56: "Delivered early, agreement with Receiver",
         57: "Delivered on request from the store",
         59: "Timed Out Return",
         60: "Driver Notification Failed",
         62: "Drivers Notification",
         65: "Additional Handling – Incorrect Postal Code Provided",
         66: "Goods Stored - Agreement With Receiver",
         67: "Re-labeling",
         68: "Production delay",
         72: "Re-directed by Receiver",
         73: "Parcel to Groupage conversion",
         74: "Force Majeure",
         75: "Capacity shortage",
         76: "Mailbox without signature",
         77: "Receiver Unknown",
         78: "Private Receiver",
         82: "Lack of delivery time",
         85: "Customer deviation",
         86: "Less items than ordered",
         87: "Delivered to wrong receiver",
         88: "Box Address changed to delivery address, time guarantee expires",
         89: "Incorrect/unreadable/incomplete address", 
         90: "As Agreed with Receiver",
         101: "Have left the Area",
         102: "Don’t have time",
         104: "Duplicate item",
         113: "Incorrect product chosen",
         131: "Driver notification by phone successful",
         132: "Saved according to delivery guarantee",
         133: "Incorrectly sorted by sender",
         135: "Item delayed at Distributing hub",
         134: "Item delayed at pickup hub",
         137: "Can not be delivered in a parcel box",
         138: "Refused by recipient, incomplete delivery",
         140: "Delivery area is not accessable",
         145: "Product terms exceeded",
         182: "Barcode not readable / missing",
         225: "Parcel to big for parcel locker",
         226: "No contact with NDM",
         227: "No contact with Nærboks",
         228: "Nærboks app logs of before barcode",
         229: "Box/Locker malfunction",
         230: "other reasons",
         231: "Barcode Unknown",
         232: "Wrong number/not approved EUR pallet",
         237: "Letter Box Pickup Successfull",
         238: "Letter Box Pickup Failure",
         241: "Equipment to assist the delivery not available",
         242: "Temporarily closed",
         243: "Permanently closed ",
         244: "Not the closest due to possible system failure",
         245: "The place does not receive any more packages",
         246: "By agreement with the sender",
         247: "By agreement with KUL",
         248: "Others",
         249: "Reached daily limit per local agreement",
         251: "Redirected to return office",
         252: "Return to sender",
         263: "Retur fra Distribution",
         266: "Name or necessary information missing",
         267: "Internal issues",
         268: "Insecure location to deliver parcel unattended",
         269: "Deliveryitem Postbox Size issue"
     };
     
     
     static readonly DPDEventCodes = {
         0: "None",
         1: "Consolidation",
         2: "Inbound",
         3: "Out for delivery",
         4: "Drivers return",    
         5: "PickUp",
         6: "System return",
         8: "Warehouse",
         9: "Inbound exception",
         10: "HUB scan",
         12: "Customs clearance process",
         13: "Delivered",
         14: "Delivery attempt not successful",
         15: "Drivers pickup",
         17: "Export import cleared",
         18: "Additional information", 
         20: "Loading",
         23: "Delivered by driver to DPD parcel shop"
     };
     
     static readonly  DPDExceptionCodes = {
         0: "None",
         2: "Loading error",
         3: "Routing error",
         4: "Packaging damaged",
         11: "Wrong address",
         12: "Refused by consignee packaging damaged",
         14: "Refused by consignee not ordered",
         15: "Refused by consignee",
         16: "Refused by consignee contents damaged",
         19: "Consignee not present 1st time",
         20: "Consignee self collect",
         25: "Closed department store",
         27: "Lack of space",
         30: "Consignee on holiday",
         31: "Not system conform",
         32: "Lack of time",
         35: "Consignee not present",
         41: "Force majeure",
         42: "Consignee not present 2nd time",
         47: "Return Third Party",
         50: "Access not possible",
         59: "Private Address",
         68: "Delivery Deposit With Non Recurring Authority",
         69: "Delivery Deposit With General Authorisation",
         71: "Customer not informed",
         72: "Parcel not ready for shipment",
         73: "Parcel shipped by other means",
         86: "No forwarding on the inbound day",
         89: "Delivery to mail or parcelbox without signature",
         91: "Parcel shop after 1st attempt"
     };
 
     static readonly EllaEventCodes:any = {
         0: "None",
         11: "Inhämtat Hos Kund",
         28: "Ankommit För Distribution",
         31: "Utlämnat Mottagare Kollireg",
         42: "Lantbrevbäring, kvitterat PK",
         43: "Overlämning Serviceställe",
         68: "Avgång DIP",
         71: "Överlämnat DIP",
         801: "Receiver not present",
         820: "No access"
     }
     
     static  readonly EllaDeviationCodes = {
         0: "None",
         450: "Färre Kollin Än Bestallningen Inneholl",
         451: "Felaktigt vald tjänst",
         452: "Felaktig Adress",
         453: "Finns Inget Gods För Upphämtning",
         460: "Avsändaren Ej Anträffbar",
         461: "Gods Ej Klart For Upphämtning",
         502: "Skadad förpackning åtgärdad",
         508: "Fel antal/ej godkänd EUR-pall",
         516: "Dubblettkolli",
         549: "Chaufförsavisering lyckad",
         550: "Ttx kan ej utföras",
         555: "Räddat enl. leveransgaranti ",
         602: "Postnr fel angivet av kund",
         603: "Kolli felsorterat",
         604: "Box ändrad till leveransadress, tidsgaranti upphör",
         613: "Överskridna produktvillkor",
         642: "Felsorterat avsändare",
         651: "Kolli kvar på inh DIP",
         652: "Kvarligg spridande DIP",
         701: "Kolli stoppat, bristande förp.",
         702: "Kolli stoppat, farligt gods",
         705: "Fel i frakthandling",
         807: "TelefonaviseradLevEnligtÖk",
         811: "Telefonavisering Misslyckades",
         816: "Vägran ej komplett leverans",
         830: "Leveransområde ej tillgängligt (Force Majuer)"
     }
 }