import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectionOption } from '../../entity/utility-interfaces';

@Component({
  selector: 'app-single-select-dropdown',
  templateUrl: './single-select-dropdown.component.html'
})
export class SingleSelectDropdownComponent {

  @Input()
  selectedOption: any;
  @Input() data: SelectionOption[] = [];
  @Output() selectionChange = new EventEmitter();
  @Input() placeholder: string | undefined;
  @Input() className: string | undefined;
  @Input() width: string | undefined;
  @Input() isDisabled?: any;
  constructor() {
  }

  onClickSelectionChange() {
    this.selectionChange.next(this.selectedOption.id);
  }

}
