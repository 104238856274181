import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DataAccessService } from '../../services/data-access/data-access.service';
import { DataRepositoryService } from '../../services/data-repository/data-repository.service';
import { CommonService } from '../../services/common/common.service';
import { MenuItem } from 'primeng/api';
import { LoaderService } from '../../services/loader/loader.service';
import {  Router } from '@angular/router';
import { Navigation } from '../../entity/navigation';

@Component({
  selector: 'app-actionbar',
  templateUrl: './actionbar.component.html',
  styleUrls: ['./actionbar.component.scss']
})
export class ActionbarComponent {

  @Input() hideAppOptions: boolean = true;
  @Output() appOptionsStatus = new EventEmitter();
  @Output() sectionFullScreen = new EventEmitter();

  navigation = Navigation;
  isFullScreen: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.toggleHeader();
  }
  items: MenuItem[] | undefined;
  desktopView = true;
  blName: string = '';
  selectedDate: string = '';
  totalTasks: number = 0;
  totalRoutes: number =0;
  totalItems: number = 0;
  keyMatrix: any;
  routePage: any;
  expandState: number = 0;
  icon: string = 'pi pi-chevron-circle-right';
  tooltipName: string = 'Expand KPI';
  isBLchanged: boolean = false;
  constructor(
    public userAuthService: AuthService
    , private dataAccessService: DataAccessService
    , private dataRepositoryService: DataRepositoryService
    , private commonService: CommonService,
    private loaderService: LoaderService,
    private router: Router
  ) {
    this.router.events.subscribe((val:any) => {
      this.routePage = this.router.url;
    })
  }
  ngOnInit(): void {
    this.items = [
      {
          label: 'Options',
          items: [
              {
                  label: 'Settings',
                  icon: 'pi pi-cog',
              }
          ]
      }
  ];
    this.toggleHeader();

    this.dataAccessService.isBLSelected.subscribe(res => {
      if (res) {
        this.getStoredData();
      }
    })

    /**
     * This filter subscription is executed when BL is changed from filter
     */
    this.dataAccessService.fitlerApplied$.subscribe(
      data => {
        if (data) {
          this.isBLchanged = true
          this.getStoredData();
          this.resetMetrices();
        }
      }
    )
    this.dataAccessService.kpiData$.subscribe(
      data => {
        if (data) {
          this.getStoredData()
        }
      }
    )

    this.dataAccessService.currentSelectedRoute.subscribe((selectedData: any) => {
      if (selectedData) {
        this.getKeyMetricValues();
      }
    })

    this.dataAccessService.kpiDeltaUpdates$.subscribe(
      (data:any)=>{
        if(data){
          this.getStoredData();
        }
      }
    )
    
  }

  onLogout(){
    this.dataAccessService.clearLocalStorage();
    this.userAuthService.logout();
  }

  toggleExpandView() {
    this.expandState = (this.expandState + 1) % 3;
    this.loaderService.setExpandData(this.expandState);
    this.updateIconandTooltip();
  }
  
  updateIconandTooltip() {
    switch (this.expandState) {
      case 1:
        this.icon = 'pi pi-chevron-circle-down';
        this.tooltipName = 'Expand Task Bank';
        break;
      case 2:
        this.icon = 'pi pi-chevron-circle-up';
        this.tooltipName = 'Both Tables';
        break;
      case 0:
      default:
        this.icon = 'pi pi-chevron-circle-right';
        this.tooltipName = 'Expand KPI';
        break;
    }
  }
  toggleAppOptions() {
    this.hideAppOptions = !this.hideAppOptions;
    this.appOptionsStatus.emit(this.hideAppOptions);
  }

  toggleHeader() {
    if (window.innerWidth <= 834) {
      this.desktopView = false;
    } else {
      this.desktopView = true;
    }
  }

  getStoredData(){
    let bl: any = this.dataRepositoryService.selectedBusinessLocation;
    this.blName = this.dataRepositoryService.businessLocationListMap.get(bl)?.name ?? '';

    let storedDateOffset: any = this.dataRepositoryService.dateOffset;
    if (storedDateOffset && JSON.parse(storedDateOffset) != '') {
      let date = new Date(new Date().setDate(new Date().getDate() + JSON.parse(storedDateOffset)));
      this.selectedDate = this.commonService.formatDate(date);
    }
    else {
      this.selectedDate = this.commonService.formatDate(new Date());
    }
    if(!this.isBLchanged){
      this.getKeyMetricValues();
    }
  }

  getKeyMetricValues(){
    const selectedRouteIds = this.dataRepositoryService.selectedRouteList.map(route => route.route_id);
    const kpiDataValue = [...this.dataRepositoryService.kpiDataMap.values()]
    let routesKeyMetrics = selectedRouteIds.length ? kpiDataValue.filter(kpi => selectedRouteIds.includes(kpi.route_id)) : kpiDataValue;
    this.totalTasks = 0;
    this.totalItems = 0;
    this.totalRoutes = routesKeyMetrics.length ?? 0;
    routesKeyMetrics?.forEach((route:any) => {
      this.totalTasks += route?.kpi?.tasks?.total ?? 0;
      this.totalItems += route?.kpi?.items?.total ?? 0;
    });

    this.setKeyMetrics();
  }

  /**
   * Method resets the keymtrices values to defaults
   */
  resetMetrices(){
    this.totalTasks = 0;
    this.totalItems = 0;
    this.totalRoutes = [...this.dataRepositoryService.kpiDataMap.values()].length ?? 0;
    [...this.dataRepositoryService.kpiDataMap.values()]?.forEach((route:any) => {
      this.totalTasks += route?.kpi?.tasks?.total ?? 0;
      this.totalItems += route?.kpi?.items?.total ?? 0;
    });
    this.setKeyMetrics();

    //set back the isBLchanged flag
    this.isBLchanged = false;
  }

  setKeyMetrics() {
    this.keyMatrix = [
      {
        task: 'Routes',
        value: this.totalRoutes,
        icon: 'pi pi-truck',
      },
      {
        task: 'Tasks',
        value: this.totalTasks,
        icon: 'pi pi-inbox'
      },
      {
        task: 'Items',
        value: this.totalItems,
        icon: 'pi pi-gift'
      }]
  }

  navigate(item:any) {
    window.open('#/setting','_blank');
  }
}
