import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  showOverlay = false;
  spinner: any;

  constructor(
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.spinner = this.loader.on('loader', this.setFlag);
  }

  setFlag = (flag: boolean) => {
    this.showOverlay = flag;
  }
}
