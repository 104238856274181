/**
 * Constants Values for Navigation
 */
export const ColorCodes = [
  "#e6194B", // Red
  "#4363d8", // Blue
  "#f032e6", // Magenta
  "#9A6324", // Brown
  "#1EFF00", // Neon Green
  "#DFAAE4", // Light Mauve
  "#3DC1E6", // Bright Cyan 
  "#ff9933", // indan Saffron
  "#3DE6BC", // Medium Aquamarine
  "#235b54", // Dark Slate Gray
  "#469990", // Teal
  "#000000", // Black
  "#f58231", // Orange
  "#ffe119", // Yellow
  "#3cb44b", // Green
  "#bfef45", // Lime
  "#42d4f4", // Cyan
  "#911eb4", // Purple
  "#a9a9a9", // Grey
  "#86185E", // French Plum
  "#B35F5B", // Mint
  "#CE6BFF", // Heliotrope
  "#800000", // Maroon
  "#c56133", // Ruddy Brown
  "#E63DA2", // Deep pinkish Magenta
  "#3750db", // Palatinate Blue
  "#e68f66", // Big Foot Feet
  "#96341c", // Deep Dumpling
  "#C99B0F", // Gold Foil
  "#2f2aa0", // Blue (Pigment)
];