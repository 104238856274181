import { Pipe, PipeTransform } from '@angular/core';
import { TaskSource, TaskState, TaskType } from '../../models/core';
import { RouteState, TBTabName } from '../../entity/enums';
import { DataRepositoryService } from '../../services/data-repository/data-repository.service';

@Pipe({
    name: 'TaskSelectPipe',
    pure: false
})
export class TaskSelectPipe implements PipeTransform {
  activetab: any = TBTabName;
    constructor(
        private dataRepositoryService: DataRepositoryService
    ) { }
    /**
     * 
     * @param tasks task object contains task's information
     * @param tabTabName handling movement based on active tab
     * @param movedTaskBLId move task based on business location
     * @returns boolean value
     */
    transform(tasks: any, tabTabName: string, movedTaskBLId?: string): boolean {
      let isMovementAllowed = true;
      this.activetab = tabTabName.toLowerCase();
     
      switch (this.activetab) {
        case TBTabName.Planned:
          isMovementAllowed = this.handlePlannedTab(tasks);
          break;
        case TBTabName.Unplannedpickup:
          isMovementAllowed = this.handleUnplannedPickupTab(tasks, movedTaskBLId);
          break;
        case TBTabName.PlannedPBM:
          isMovementAllowed = this.handlePlannedPBM(tasks, movedTaskBLId);
          break;
      }
     
      return isMovementAllowed;
    }
    /**
     * handling planned task movement
     * @param tasks task object contains task's information
     * @returns boolean value
     */ 
     handlePlannedTab(tasks: any): boolean {
      let isMovementAllowed = true;
     
      if (!this.checkDateOffset() || !this.checkAddressMissing(tasks)) {
        isMovementAllowed = false;
      } else {
        const taskState = tasks?.task_state?.toLowerCase();
        isMovementAllowed = taskState !== TaskState.Completed && this.checkTaskSource(tasks) && this.checkTaskType(tasks?.task_type);
      }
     
      return isMovementAllowed;
    }
     /**
      * handling unplanned pickup task movement
      * @param tasks task object contains task's information
      * @param movedTaskBLId move task based on business location
      * @returns boolean value
      */
     handleUnplannedPickupTab(tasks: any, movedTaskBLId?: string): boolean {
      return this.moveTaskBL(tasks, movedTaskBLId);
    }
    /**
     * handling plannedPBM task movement
     * @param tasks  task object contains task's information
     * @returns boolean value
     */
    handlePlannedPBM(tasks:any ,movedTaskBLId?: string):boolean{
      let isMovementAllowed = true;
      if (!this.checkDateOffset() || !this.checkAddressMissing(tasks)) {
        isMovementAllowed = false;
      } else {
        const taskState = tasks?.task_state?.toLowerCase();
        isMovementAllowed = taskState !== TaskState.Completed && this.checkTaskType(tasks?.task_type);
      }
      if(isMovementAllowed){
        isMovementAllowed = this.moveTaskBL(tasks, movedTaskBLId);
      }
      return isMovementAllowed;
    }
     /**
      * 
      * @param tasks task object contains task's information
      * @param movedTaskBLId move task based on business location
      * @returns boolean value
      */
    moveTaskBL(tasks: any, movedTaskBLId?: string): boolean{
      let isMovementAllowed = true;
     
      if (!this.checkDateOffset()) {
        isMovementAllowed = false;
      } else if (movedTaskBLId) {
        if (this.checkAddressMissing(tasks)) {
          isMovementAllowed = movedTaskBLId === '' || movedTaskBLId === tasks.business_location_id;
        } else {
          isMovementAllowed = false;
        }
      } else {
        isMovementAllowed = this.checkAddressMissing(tasks);
      }
     
      return isMovementAllowed;
    }
       
    /**
     * Method checks the task source as PBM or non-PBM and returns boolean flag 
     * based on certain conditions
     * @param task task object contains task's information
     * @returns boolean value
     */
    checkTaskSource(task: any): boolean {
      let isMovementAllowed = false;
      const taskState = task?.task_state?.toLowerCase();
      const taskSource = task.source.toLowerCase();
     
      if (taskSource === TaskSource.PBM) {
        isMovementAllowed = true;
      } else if (taskState === TaskState.Planned) {
        const routeState = task?.route_state.toLowerCase();
        isMovementAllowed = routeState === RouteState.Sorting || routeState === RouteState.Loading;
      }
     
      return isMovementAllowed;
    }

    /**
     * Method checks the task type and returs false if type is 
     * BREAK
     * else returns true
     * @param task task object contains task's information
     * @returns boolean value
     */
  checkTaskType(task_type: string) {
    let allowedTaskType: boolean = true;
    switch (this.activetab) {
      case TBTabName.PlannedPBM.toLowerCase():
        if (task_type.toLowerCase() === TaskType.Visit || task_type.toLowerCase() === TaskType.Break) {
          allowedTaskType = false;
        }
        break;
      case TBTabName.Planned.toLowerCase():
        if (task_type.toLowerCase() === TaskType.Break) {
          allowedTaskType = false;
        }
        break;
      case TBTabName.Unplannedpickup.toLowerCase():
        break;
      default:

    }

    return allowedTaskType;
  }

    /**
     * Method checks the system's date and returns true if it is 
     * present day else returns false
     * @returns boolean value
     */
    checkDateOffset() {
        let allowedDateOffset: boolean = true;
        if (this.dataRepositoryService.dateOffset !== 0) {
            allowedDateOffset = false;
        }

        return allowedDateOffset;
    }

    
    /**
     * Method check the address of the task, if available returns TRUE else FALSE
     * @param task task object
     * @returns boolea value
     */
    checkAddressMissing(task: any): boolean {
        let isAddressAllowed: boolean = false;
        if (Boolean(task.address) && task.address != '') {
            isAddressAllowed = true;
        }
        return isAddressAllowed
    }

}
