<nav id="sidebar" [ngClass]="{'active': !hideAppOptions}">
  <ul class="navbar-nav mr-auto">
    <li class="nav-item app-option" *ngFor="let option of menuOptions">
      <div class="menu-item" >
         <div *ngIf="option.subOption">
            <img *ngIf="option.icon" [src]="option.icon" class="option-icon" alt="option icon">
            <span (click)="handleMenuClick(option)">{{ option.name }}</span>
            <i *ngIf="option.subOption"  (click)="expandOption(option)" class="pi pi-chevron-right dropdown-icon"
            [ngClass]="{'rotate':activeOption ===option}">
            </i>
         </div>
         <div *ngIf="!option.subOption" (click)="handleMenuClick(option)">
          <img *ngIf="option.icon" [src]="option.icon" class="option-icon" alt="option icon">
          <span >{{ option.name }}</span>
         </div>
      </div>
      <ul *ngIf="option.subOption && activeOption === option">
        <li class="nav-item app-option sub-option" *ngFor="let child of option.subOption" (click)="navigateTo(child); $event.stopPropagation()">
          <i *ngIf="child.icon" [ngClass]="child.icon"></i>
          {{child.name}}
        </li>
      </ul>
    </li>
  </ul>
</nav>