import { Injectable } from '@angular/core';
import { Address, BusinessLocation, Coordinate, Teams } from '../../models/business-location-details';
import { RouteInfo, Routes } from '../../models/kpi-details';
import { IPlannedPBMTasksMap, ITasksMap } from '../../models/tasks-planned';
import { SelectionOption, TaskMoveCommand, PullBackTaskCommand } from '../../entity/utility-interfaces';
import { IUPPTasksMap } from '../../models/task-unplannedpickup';
import { IUnloadedTasksMap } from '../../models/task-unloaded';

@Injectable({
    providedIn: 'root'
})
export class DataRepositoryService {
    [x: string]: any;

    constructor() { }

    /**
     * use this service class for glabal variables that should be available through out the application for all the component under single module.
     */

    /**
     * ******************Data Collection***********************
     */
    /**
     * Business location data collection - map object 
     */
    businessLocationListMap: Map<string, BusinessLocation> = new Map<string, BusinessLocation>();

    /**
     * ******************User Information******************
     */
    loggedInUser: any;

    /**
     * KPI table data collection - map object 
     */
    kpiDataMap: Map<string, Routes> = new Map<string, Routes>();

    /**
     * Business location filtered route list
     */
    routesList: RouteInfo[] = [];

    /**
     * Global Filter selection options 
     * Business Location, Teams, Routes, Route State, Canlendar date offset
     */

    selectedBusinessLocation: string = '';
    /**
     * Business Location Teams list 
     */
    teamList: Map<string, Teams> = new Map<string, Teams>();
    
    /**
     * Selected Teams of Business Location 
     */
    selectedTeamList: Map<string, SelectionOption> = new Map<string, SelectionOption>();

    
    /**
     * Business location All available route list
     */
    blAllRouteList: string[] = [];

    /**
     * selected filtered route list
     */
    selectedFilteredRouteList: string[] = [];

    /**
     * Removed routes from the filter 
     */
    removedRouteIds: string[] = [];

    /**
     * Map collection for Team and Route association 
     */
    blTeamRouteAssociation: Map<string, any> = new Map<string, any>();
    /**
     * Map collection for global filter applied Team and Route association 
     */
    selectedTeamRouteAssociation: Map<string, any> = new Map<string, any>();

    /**
     * Date offset selected from the calendar  
     */
    dateOffset: number = 0;
    
    /**
     * Selected Route Keys List from the KPI
     */
    selectedRouteList: any[] = [];
    
    /**
     * Selected Route Keys List from the KPI
     */
    selectedFilteredRouteState: string = 'all';

    /**
     * ****************Etag information**********************
     */

    /**
     * etag value for routeInfo endpoint, to be used to call delta of routeInfo, it should be updated with every routeinfo response etag value. 
     */
    routeInfoEtag: number = 0;

    /**
     * etag value for Planned Tab endpoint, to be used to call delta of TaskDetails, it should be updated with every PlannedtaskInfo response etag value. 
     */
    plannedTbEtag: number = 0;
     /**
     * etag value for Unloaded Tab endpoint, to be used to call delta of TaskDetails.
     */
    unloadedTbEtag: number = 0;
     /**
     * etag value for PushToCloud Tab endpoint, to be used to call delta of TaskDetails.
     */
    cloudTbEtag: number = 0;

    /**
     * Task Bank Planned Tab data collection - map object 
     */
    plannedTbCollection: Map<string, ITasksMap> = new Map<string, ITasksMap>();

    /**
    * etag value for unplanned Tab endpoint, to be used to call delta of TaskDetails, it should be updated with every unplannedPickup response etag value. 
    */
    unplannedTbEtag: number = 0;
    /**
    * Task Bank UnPlannedPickup Tab data collection - map object 
    */
    unplannedTbCollection: Map<string, IUPPTasksMap> = new Map<string, IUPPTasksMap>();
    /**
   * etag value for plannedpbm Tab endpoint, to be used to call delta of TaskDetails, it should be updated with every plannedpbm response etag value. 
   */
    plannedPbmTbEtag: number = 0;
    /**
    * Task Bank Planned PBM Tab data collection - map object 
    */
    plannedPbmTbCollection: Map<string, IPlannedPBMTasksMap> = new Map<string, IPlannedPBMTasksMap>();
    /**
    * Task Bank Unloaded Tab data collection - map object 
    */
    unloadedTbCollection :Map<string, IUnloadedTasksMap> = new Map<string, IUnloadedTasksMap>();
    /**
    * Task Bank PushToCloud Tab data collection - map object 
    */
    cloudTbCollection :Map<string, IUPPTasksMap> = new Map<string, IUPPTasksMap>();

    /**
     * *****************************Task Movement Information************************
     */
    taskMovementData: TaskMoveCommand = {} as TaskMoveCommand;
    /**
     * *****************************Pull Back Task Movement Information************************
     */
    pullBacktaskMovementData: PullBackTaskCommand = {} as PullBackTaskCommand;
    /**
     * List of all the selected task for movement
     */
    movingTasksMap: Map<string, any> = new Map<string, any>();

    /**
     * Place where color code set to route_id
     */
    routesColour: Map<string, string> = new Map<string, string>();

    /**
     * To get address based on address_id
     */
    businessLocationAddress: Map<string, Address> = new Map<string, Address>

    /**
     * To hold business location coordinates
     */
    blCoordinates: Coordinate;

    /**
     * ******************************Higlighted task on Map and TaskBank*************
     */
    highlightedTaskOnMap: Map<string, string> = new Map<string, string>();
}
