import { Injectable } from '@angular/core';
import { Item, KPI, RouteInfo, RouteInfoAPIResponse, RouteInfoResp, Routes, Stop, Task } from 'src/app/shared/models/kpi-details';
import { DataRepositoryService } from 'src/app/shared/services/data-repository/data-repository.service';
import { DataFormatService } from 'src/app/shared/services/format/data-format.service';

@Injectable({
    providedIn: 'root'
})
export class ResponseDataFormatService {

    constructor(
        private dataRepositoryService: DataRepositoryService
        ,private dataFormatService: DataFormatService
    ) { }

    resModelFormatter(routeInfoRes: RouteInfoAPIResponse): RouteInfoAPIResponse {
        let routes: RouteInfoAPIResponse;

        let routeInfo = this.getRouteInfo(routeInfoRes);
        routes = {
            etag: routeInfoRes?.etag ?? 0,
            route_info: routeInfo
        }

        return routes;
    }

    getRouteInfo(routeInfoList: RouteInfoResp): RouteInfo[] {
        let routeInfo: RouteInfo[] = [];
        routeInfoList.route_info.forEach(rI => {
            routeInfo.push({
                business_location_id: rI.business_location_id ?? '',
                business_location_name: this.dataRepositoryService.businessLocationListMap.get(rI.business_location_id)?.name??'',
                routes: this.getRoutes(rI.routes)
            })

        })
        routeInfo = this.sort(routeInfo, 'business_location_name');
        return routeInfo;
    }

    getRoutes(routeList: Routes[]): Routes[] {
        let routes: Routes[] = [] as Routes[];
        routeList.forEach((route: Routes) => {
            let routeObj = {
                kpi_extended:route.kpi_extended,
                kpi: this.getKPI(route.kpi),
                route_id: route.route_id,
                route_label: route.route_label,
                route_schedule: route.route_schedule,
                route_state: route.route_state,
                route_time_window: route.route_time_window,
                failed_tasks: route.failed_tasks
            }
            routes.push(routeObj)
        })
        routes = this.sort(routes, 'route_label');
        routes.forEach(route=>{
            this.dataRepositoryService.kpiDataMap.set(route.route_id, route);
        })
        return routes;
    }


    getKPI(kpi: KPI): KPI {
        return {
            items: this.getItems(kpi.items),
            stops: this.getStops(kpi.stops),
            tasks: this.getTasks(kpi.tasks)
        } as KPI
    }

    getTasks(task: Task): Task {
        return ({
            completed: task.completed,
            deliver: task.deliver,
            emptymailbox: task.emptymailbox,
            handover: task.handover,
            loaded: task.loaded,
            pickup: task.pickup,
            planned: task.planned,
            tasks_left: task.total - task.completed,
            completedPercentage: Number(((task.completed/task.total)*100).toFixed(2)),
            tasks_pr_hour: task.tasks_pr_hour,
            total: task.total,
            visit: task.visit
        })
    }

    getStops(stop: Stop): Stop {
        return ({
            completed: stop.completed,
            loaded: stop.loaded,
            total: stop.total
        })
    }

    getItems(item: Item): Item {
        return ({
            delivered: item.delivered,
            failedattempt: item.failedattempt,
            letter: item.letter,
            loaded: item.loaded,
            parcel: item.parcel,
            pickup: item.pickup,
            planned: item.planned,
            total: item.total,
            volume: item.volume,
            weight: item.weight,
            csp_sorted: item.csp_sorted,
            notified: item.notified,
            pickup_completed: item.pickup_completed,
            pickup_failed: item.pickup_failed,
            signature_required: item.signature_required
        });
    }

    //processing the route info object

    sort(input:any, prop: any) {
        return this.dataFormatService.sortByProperty(input, prop);
    }

    setRouteMap(routes: Routes[]) {
        routes.forEach(route => {
            this.dataRepositoryService.kpiDataMap.set(route.route_id, route);
        })
    }

    //delta implementation 

    deltaAdaptArray(routeInfoResponse: any) {
        routeInfoResponse.route_info.forEach((rI:any) => {
            this.updateRoutes(rI.routes)
        })
        return routeInfoResponse;
    }

    updateRoutes(routeList: Routes[]) {
        routeList.forEach((route: Routes, index) => {
            let routeObj = {
                kpi_extended:route.kpi_extended,
                kpi: this.getKPI(route.kpi),
                route_id: route.route_id,
                route_label: route.route_label,
                route_schedule: route.route_schedule,
                route_state: route.route_state,
                route_time_window: route.route_time_window,
                failed_tasks: route.failed_tasks
            };
            this.dataRepositoryService.kpiDataMap.set(route.route_id, routeObj);
        })
        const sortRoute = new Map([...this.dataRepositoryService.kpiDataMap.entries()].sort((a, b) => a[1].route_label.localeCompare(b[1].route_label)));
        this.dataRepositoryService.kpiDataMap = sortRoute;
        //for reference change, use temp assignment, so that ngOnChange hook can be triggered
        const newRouteMap = new Map(this.dataRepositoryService.kpiDataMap.entries());
        this.dataRepositoryService.kpiDataMap = newRouteMap;
    }
}