import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectionOption } from '../../entity/utility-interfaces';

export class MultiSelectOption {
  id: string = '';
  value: string = '';
}
@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: `./multi-select-dropdown.component.html`
})
export class MultiSelectDropdownComponent {
  @Input() selectedOption: any;
  @Input() data: SelectionOption[]=[];
  @Output() selectionChange = new EventEmitter();
  @Input() placeholder: string | undefined;
  @Input() className: string | undefined;
  @Input() width: string | undefined;
  constructor() {
  }

  onTeamsSelectionChange(event: any) {
    this.selectionChange.next(this.selectedOption);
  }

}
