<div id="logout-wrapper">
  <header>
    <nav class="navbar navbar-expand-sm">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="navbar-brand">
            <img src="../../../../assets/image/postnord.svg" alt="logo" class="img-width">
          </a>
          <span *ngIf="desktopView" class="app-name">Distribution Planner</span>
        </li>
        <li class="logout-button" (click)="logout()">
          <button class="btn btn-ripple btn-pn-primary">Logout</button>
        </li>
      </ul>
    </nav>
  </header>

  <div class="errorMsg">
    <div class="errorImg"><img src="../../../../assets/image/error_lock.svg" alt="errorlock"></div>
    <div class="errorHeader">Access Denied</div>
    <div class="errorText">
      It looks like you do not have permission to access the requested page. Contact your Administrator for details.
    </div>
  </div>
</div>