import { Component} from '@angular/core';
import { MessageService } from 'primeng/api';
import { ErrorMessage, ErrorType } from 'src/app/shared/entity/error.const';
import { DataAccessService } from 'src/app/shared/services/data-access/data-access.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  hideAppOptions: boolean = true;

  constructor(
    private dataAccessService : DataAccessService,
    private messageService :MessageService
  ) { }


  appOptionsStatus(status: boolean) {
    this.hideAppOptions = status;
  }
  ngOnInit(){
    this.dataAccessService.isSelectedRouteEmpty$.subscribe((isRouteEmpty :boolean) =>{
        if(isRouteEmpty){
        this.messageService.add({
          severity:ErrorType.WARNING,
          summary : "",
          detail:ErrorMessage.NOTASKONROUTE,
        })
      }

  })
  }

}
