import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  outgoingServicesCount = 0;
  private loader = new Subject<any>();


  private expandTable = new BehaviorSubject<number>(0)
  expand$ = this.expandTable.asObservable()
  listeners: any = {};
  events;

  constructor() {
    this.events = this.loader.asObservable();
    this.events.subscribe(({ name, args }) => {
      if (this.listeners[name]) {
        for (const listener of this.listeners[name]) {
          listener(...args);
        }
      }
    });
  }

  setExpandData(section:number){
    this.expandTable.next(section)
  }

  toggleLoader(serviceFlag: number) {
    switch (serviceFlag) {
      case 0:
        // service returned response
        this.outgoingServicesCount -= 1;
        if (this.outgoingServicesCount <= 0) {
          this.outgoingServicesCount = 0;
          this.broadcast('loader', false);
        }
        break;
      case 1:
        // service called
        this.outgoingServicesCount += 1;
        this.broadcast('loader', true);
        break;
    }
  }

  on(name: any, listener: any) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }
    this.listeners[name].push(listener);
  }

  broadcast(name: any, ...args: any) {
    this.loader.next({
      name,
      args
    });
  }
}
