<p-button (click)="showDialog()" onKeyDown="showDialog()" class="pointer-cursor" [ngClass]="filterIcon"></p-button>
<p-dialog header="Filter" [resizable]="false"  
[focusOnShow]="false" [draggable]="false" [(visible)]="display" (onHide)="closePopup()" [modal]="true" [style]="{ width: '50vw', height: '88%' }">
  <div id="filter-wrapper" class="container">
    <p-divider align="left">
      <div class="inline-flex">
        <i class="pi pi-calendar mr-4"></i>
        <b> Select Date</b>
      </div>
    </p-divider>
    <div class="row">
      <p-calendar [(ngModel)]="selectedDate" (onSelect)="onDateSelection()" appendTo="body" [showOtherMonths]="true"  [selectOtherMonths]="true" [minDate]="minDate" [maxDate]="maxDate" [readonlyInput]="true" [showIcon]="true"  inputId="min-max"></p-calendar>
    </div>
    <p-divider align="left">
      <div class="inline-flex">
        <i class="pi pi-list mr-4"></i>
        <b> Business Location, Teams & Routes</b>
      </div>
    </p-divider>
    <div class="row mb-2">
      <div class="col-lg-6 mb-2">
        <app-single-select-dropdown [className]="'blom-select'" width='100' [selectedOption]="selectedBLObject" [data]="businessLocationsList"
          (selectionChange)="onBLSelection($event)" [placeholder]="'Select Business Location'" ></app-single-select-dropdown>
      </div>
      <div class="col-lg-6">
        <app-multi-select-dropdown [className]="'blom-select'" width='100' [selectedOption]="selectedBlTeams" [data]="blTeamsList"
        (selectionChange)="onTeamsSelection($event)" [placeholder]="'Select Teams'" ></app-multi-select-dropdown>
      </div>
    </div> 
    <div class="row">
      <div class="col-lg-6">
        <p-multiSelect [options]="blRoutesList" autoWidth="false" optionLabel="value" [(ngModel)]="selectedBlRoutes"
          [showToggleAll]="false"
          [placeholder]="'Select Routes'" [style]="{ width: 100 + '%' }" (onChange)="onRouteSelection()" optionDisabled="isDisabled"
          selectedItemsLabel="{0} route(s) selected" [resetFilterOnHide]="true" appendTo="body">
        </p-multiSelect>
      </div>
      <div class="col-lg-6">
        <span>Total Routes: {{blRoutesList.length}}</span>
      </div>
    </div>
    <p-divider align="left">
      <div class="inline-flex">
        <i class="pi pi-check-square mr-4"></i>
        <b> Route State</b>
      </div>
    </p-divider>
    <div class="checkboxes">
      <div *ngFor="let rState of routeStates">
        <p-radioButton name="rState" value="rState" [value]="rState" [label]="rState.label" (onFocus)="onRouteStateChange()" [(ngModel)]="selectedRouteState" [inputId]="rState.state"></p-radioButton>
    </div>
    </div>
    <p-divider></p-divider>
    <div>
      <button type="button" class="btn btn-primary-outline" onKeyDown="onClearSelection();" (click)="onClearSelection()">Clear all selection</button>
      <button type="button" class="btn btn-secondary  me-2" onKeyDown="closePopup();" (click)="closePopup()">Cancel</button>
      <button type="button" class="btn btn-primary me-2" onKeyDown="applyFilter();" (click)="applyFilter()" [disabled]="isFilterDisabled">Filter</button>
    </div>
    <span class="row filter-footer-note">**Note: Please press filter to continue</span>
  </div>
</p-dialog>