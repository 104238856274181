export enum SearchComponent {
  containerDetails = 'containerDetails',
  routeSummary = 'routeSummary',
  routeDetails = 'routeDetails',
  taskBank = 'taskBank',
  containerInfo = 'containerInfo',
  barcode = 'barcode',
  consignee = 'consignee',
  productcode = 'productcode'
}

export enum TBTabName {
  Planned = 'planned',
  Unplanned = 'unplanned',
  Unplannedpickup = 'unplanned pickup',
  Unloaded = 'unloaded',
  PlannedPBM = 'planned pbm',
  UnloadedOld = 'unloaded old',
  PushToCloud= 'push to cloud'
}

export enum RouteState {
  Sorting = 'sorting',
  Planning = 'planning',
  Loading = 'loading',
  Onroute = 'onroute',
  Finishloading = 'finishloading',
  Prepareroute = 'prepareroute',
  Manualplanning = 'manualplanning',
  Cancelled = 'cancelled',
  Unknown = 'unknown',
  Completed = 'completed'
}

export enum FilterRouteState {
  AllRoutes = 'allroutes',
  Sorting = 'sorting',
  Loading = 'loading',
  OnRoute = 'onroute'
}

export enum LocalStorage {
  BusinessLocation = 'businessLocation',
  BLAllTeams = 'BLAllTeams',
  BLAllRoutes = 'BLAllRoutes',
  BLSelectedTeams = 'BLSelectedTeams',
  BLSelectedRoutes = 'BLSelectedRoutes',
  RouteState = 'RouteState',
  BLCoordinates = 'BLCoordinates'
}

export enum CommandType {
  MoveTaskCommand = 'MoveTask',
  CreateRouteCommand='CreateRoute',
  UpdateAddressCommand = 'ChangeAddress',
  PullBackTaskCommand = 'UnplanTask',
  MoveItemCommand = 'MoveItem'
}


export enum TBDataSteamType{
  Reset= "Reset",
  RemoveTask = "RemoveTask",
}

export enum ConnectionStatus{
  Online = 'online',
  Offline = 'offline'
}
export enum RouteType{
  Parcel = 'PARCEL_ROUTE',
  ThickMailRoute = 'THICK_MAIL_ROUTE',
  ThinMailRoute = 'THIN_MAIL_ROUTE',
  ManualRoute = 'MANUAL_ROUTE'
}

export enum ColorIndicationStatus {
  Breached = 'Breached',
  AboutToBreach = 'About to Breach',
  Early = 'Early',
  OnTime = 'On Time'
}