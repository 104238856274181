/**
 * Constants Values for Navigation
 */
export const Navigation = {
    Dashboard: '/dashboard',
    Map: '/map',
    MapLeaflet: '/map',
    Gantt: '/gantt',
    MainKPI: '/dashboard/mainKpi',
    Lookup:'/lookup'
}