/**
 * Constants Values for Navigation
 */
export const ColorCodes = [
  "#e6194B", // Red
  "#4363d8", // Blue
  "#f032e6", // Magenta
  "#9A6324", // Brown
  "#000075", // Navy
  "#808000", // Olive
  "#469990", // Teal
  "#000000", // Black
  "#f58231", // Orange
  "#ffe119", // Yellow
  "#3cb44b", // Green
  "#bfef45", // Lime
  "#42d4f4", // Cyan
  "#911eb4", // Purple
  "#a9a9a9", // Grey
  "#86185E", // French Plum
  "#B35F5B", // Mint
  "#CE6BFF", // Heliotrope
  "#87782C", // Pink
  "#800000", // Maroon
  "#c56133", // Ruddy Brown
  "#772b9d", // Grape
  "#235b54", // Dark Slate Gray
  "#3750db", // Palatinate Blue
  "#37294f", // Jacarta
  "#e68f66", // Big Foot Feet
  "#96341c", // Deep Dumpling
  "#C99B0F", // Gold Foil
  "#2f2aa0", // Blue (Pigment)
  "#865082", // Razzmic Berry
];