import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LayoutComponent } from './layout.component';
import { AccessRoles } from 'src/app/shared/entity/constants';
import { RoleGuard } from 'src/app/shared/services/access-roles/role-guard.service';

type PathMatch = "full" | "prefix" | undefined;

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [MsalGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import("../home/dashboard/dashboard.module").then(m => m.DashboardModule),
                data: {
                    roles:
                        [
                            AccessRoles.GLOBALADMINISTRATOR,
                            AccessRoles.ADMINISTRATOR,
                            AccessRoles.MANAGER
                        ]
                },
                canActivate: [MsalGuard, RoleGuard]
            },
            {
                path: 'map',
                loadChildren: () => import("../map/map.module").then(m => m.MapModule),
                data: {
                    roles:
                        [
                            AccessRoles.GLOBALADMINISTRATOR,
                            AccessRoles.ADMINISTRATOR,
                            AccessRoles.MANAGER
                        ]
                },
                canActivate: [MsalGuard, RoleGuard]
            },
            {
                path: 'map-leaflet',
                loadChildren: () => import("../map-leaflet/mapLeaflet.module").then(m => m.MapLeafletModule),
                data: {
                    roles:
                        [
                            AccessRoles.GLOBALADMINISTRATOR,
                            AccessRoles.ADMINISTRATOR,
                            AccessRoles.MANAGER
                        ]
                },
                canActivate: [MsalGuard, RoleGuard]
            },
            {
                path: 'lookup',
                loadChildren: () => import("../lookup/lookup.module").then(m => m.LookupModule)
            },
            {
                path: 'gantt',
                loadChildren: () => import("../gantt/gantt.module").then(m => m.GanttModule),
                data: {
                    roles:
                        [
                            AccessRoles.GLOBALADMINISTRATOR,
                            AccessRoles.ADMINISTRATOR,
                            AccessRoles.MANAGER
                        ]
                },
                canActivate: [MsalGuard, RoleGuard]
            },
            {
                path: 'routeinfo',
                loadChildren: () => import('src/app/modules/mainroute/mainroute.module').then(m => m.MainrouteModule)
            },
            {
                path: 'user-preferences',
                loadChildren: () => import('src/app/modules/setting/setting.module').then(m => m.SettingModule),
                data: {
                    roles:
                        [
                            AccessRoles.GLOBALADMINISTRATOR,
                            AccessRoles.ADMINISTRATOR,
                            AccessRoles.MANAGER
                        ]
                },
                canActivate: [MsalGuard, RoleGuard]
            },
            {
                path: '',
                redirectTo: '/dashboard',
                pathMatch: 'full' as PathMatch
            },
            {
              path:'**',
              redirectTo:'/auth',
              pathMatch:'full'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LayoutRoutingModule { }
