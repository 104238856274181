import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subject, Subscription, interval, mergeMap, take, takeLast, takeWhile } from 'rxjs';
import { IPlannedPBMTasksApiResponse, IPlannedPBMTasksMap, ITasksMap, TaskInfoResp } from '../../models/tasks-planned';
import { IUPPTasksMap, UnplannedResponse } from '../../models/task-unplannedpickup';
import { DataRepositoryService } from '../data-repository/data-repository.service';
import { TaskInfoFormatterService } from './task-info-formatter.service';
import { ConnectionService } from '../connection/connection.service';
import { EditPBMOrder, TBTabName } from '../../models/core';
import { DataAccessService } from '../data-access/data-access.service';
import { FavouriteListAPIModel, FavouriteListModel } from '../../models/favourite';
import { environment } from 'src/environments/environment';
import { ConnectionStatus, LocalStorage, TBDataSteamType } from '../../entity/enums';
import { CommunicationDataStream, TaskMoveCommand, PullBackTaskCommand } from '../../entity/utility-interfaces';
import { Navigation } from '../../entity/navigation';
import { IUnloadedTasksMap, UnloadedResponse } from '../../models/task-unloaded';
import { Router } from '@angular/router';
import { SortMeta } from 'primeng/api';
import { ErrorMessage } from '../../entity/error.const';
import { AppConstants } from '../../entity/constants';

export interface TaskBankTab {
  tab: string,
  initalApiCall: boolean,
  label: string,
  size: number
  disabled:boolean
}

@Injectable({
  providedIn: 'root'
})
export class TBCommunications {
  private dataStream: Subject<CommunicationDataStream> = new Subject();
  public dataStream$ = this.dataStream.asObservable();

  setDataStream(inputData:CommunicationDataStream){
    this.dataStream.next(inputData);
  }

}

@Injectable({
  providedIn: 'root'
})
export class TaskInfoService implements OnDestroy {
  plannedData: any[] = [];
  unplannedData: any[] = [];
  plannedPbmData:any[]=[];
  unloadedData:any[]=[];
  cloudData:any[]=[];
  routeid: string = '';
  selectedRoute: [] = []
  activeIndex;
  editPBMOrderViewType = "update";
  event = false;
  favListArray = new BehaviorSubject([]);
  favData: any;
  preferredBLlist: FavouriteListAPIModel[] = [];
  blCollectionList = [];
  BlFavList:any = []
  plannedPBMTaskMap: Map<string, IPlannedPBMTasksMap> = new Map<string, IPlannedPBMTasksMap>();
  uppTaskMap: Map<string, IUPPTasksMap> = new Map<string, IUPPTasksMap>();
  selectedUnplannedFavList:FavouriteListModel;
  selectedPlannedFavList:FavouriteListModel;
  selectedTabName:any=TBTabName.Unplannedpickup;
  taskBankTabs:TaskBankTab[] = [
    {
      tab: TBTabName.Planned,
      initalApiCall: false,
      label: TBTabName.Planned,
      size: 0,
      disabled:true
    },
    {
      tab: TBTabName.Unplannedpickup,
      initalApiCall: false,
      label: TBTabName.Unplannedpickup,
      size: 0,
      disabled:true
    },
    {
      tab: TBTabName.Unloaded,
      initalApiCall: false,
      label: TBTabName.Unloaded,
      size: 0,
      disabled:true
    },
    {
      tab: TBTabName.PlannedPBM,
      initalApiCall: false,
      label: TBTabName.PlannedPBM,
      size: 0,
      disabled:true
    },
    {
      tab: TBTabName.PushToCloud,
      initalApiCall: false,
      label: TBTabName.PushToCloud,
      size: 0,
      disabled:true
    }
  ]
  tabSubscription: Subscription = Subscription.EMPTY;
  loading: boolean = false;
  subscriptionCollecttion: Subscription[] = [];
  
  kpiSelectionDisabled = false;
  colors: string[] = [
    "#e6194B", // Red
    "#4363d8", // Blue
    "#f032e6", // Magenta
    "#9A6324", // Brown
    "#808000", // Olive
    "#469990", // Teal
    "#000075", // Navy
    "#000000", // Black
    "#f58231", // Orange
    "#ffe119", // Yellow
    "#bfef45", // Lime
    "#3cb44b", // Green
    "#42d4f4", // Cyan
    "#911eb4", // Purple
    "#a9a9a9", // Grey
    "#ffd8b1", // Apricot
    "#aaffc3", // Mint
    "#dcbeff", // Lavender
    "#fabed4", // Pink
    "#800000" // Maroon
  ];
  updateAddressPopup = {
    showAddressPopup: false,
    updateAddressTaskId: '',
    dialogHeader: 'Manage Address'
  }
  multiSortMeta:SortMeta[] = [
    { field: "route_name", order: 1 },
    { field: "stop_sequence", order: 1 }
  ]
  multiSortMetaUPP:SortMeta[] = [
    { field: "bl_name", order: 1 },
  ]
  metaKey: boolean = false;

  taskMovementErrorMsg = ErrorMessage.TASKMOVEMENTRESTRICSTIONS

  //map view and task bank task highlight communication 
  private isSelectedFromMap: Subject<{isSelectedFromMap: boolean, taskId: string, routeId?: string}> = new Subject();
  isSelectedFromMap$ = this.isSelectedFromMap.asObservable();

  private isSelectedFromPlannedTable: Subject<{isSelectedFromPlannedTable: boolean, taskId: string, routeId?: string}> = new Subject();
  isSelectedFromTaskBank$ = this.isSelectedFromPlannedTable.asObservable();

  constructor(
      private connectionService: ConnectionService
    , private taskInfoFormatterService: TaskInfoFormatterService
    , public dataRepositoryService: DataRepositoryService
    , public router: Router
    , private tbcom: TBCommunications
    , public dataAccessService : DataAccessService
    
  ) { 

    this.activeIndex = this.taskBankTabs[1]
    
    setTimeout(() => {
      this.getFavList()
    }, 1000);

    this.subscriptionCollecttion.push(this.dataAccessService.currentSelectedRoute.subscribe((selectedData: any)=> {
      if (selectedData) {
        if (selectedData.isChecked) {
          this.selectedTabName = TBTabName.Planned;
          this.getPlannedData(selectedData);
        }
        else {
          this.removeDeselectedRoute(selectedData?.routeId);
          this.publishUpdates();
        }
      }
    }))

    this.subscriptionCollecttion.push(this.dataAccessService.fitlerApplied$.subscribe(
      data => {
        if (data) {
          //All the TB tab should call the delta=false first time again after BL change
          this.taskBankTabs.forEach((tab: TaskBankTab) => {
            tab.initalApiCall = false;
            tab.size = 0;
          });
          this.activeIndex = this.taskBankTabs[1];

          //UPP tab
          this.getUnplannedPickupData(this.dataRepositoryService.selectedBusinessLocation);
          this.favListResetToDefaultBusinessLocation();

          //planned tab
          this.loadData();
          this.resetPlannedTab();

        }
      }
    ));

    this.dataAccessService.connectionSubscription$.subscribe((connectionStatus: string) => {
      if (connectionStatus.toLowerCase() === ConnectionStatus.Offline) {
        this.tabSubscription.unsubscribe();
      }
      if (connectionStatus.toLowerCase() === ConnectionStatus.Online) {
        this.activeTabSubscription();
        this.getTaskBankDelta();
      }
    })
  }

  ngOnDestroy(): void {
    this.tabSubscription.unsubscribe();
  }
  getPlannedData(selectedRoute: any) {
    this.loading = true;
    this.getPlanned(selectedRoute.routeId, this.dataRepositoryService.dateOffset, this.dataRepositoryService.plannedTbEtag, this.event).pipe(take(1)).subscribe({
      next: (response: TaskInfoResp) => {
        this.taskInfoFormatterService.taskAdaptArray(response, selectedRoute);
        this.dataRepositoryService.plannedTbEtag = response.etag;
        this.setTaskBankCount(this.dataRepositoryService.plannedTbCollection, TBTabName.Planned);
        this.dataAccessService.plannedRevised(false);
        // this.dataAccessService.plannedRevised([...this.dataRepositoryService.plannedTbCollection.values()], false);
        this.kpiSelectionDisabled = false;
      },
      error: (error) => {
        this.kpiSelectionDisabled = false;
        this.loading = false;
        this.enableTaskBankTab();
      },
      complete: () => {
        if (this.dataRepositoryService.plannedTbCollection?.size) {
          if (this.selectedTabName.toLowerCase() === TBTabName.Planned.toLowerCase() && this.dataRepositoryService.dateOffset === 0 && (this.tabSubscription?.closed || !this.tabSubscription)) {
            this.getTaskBankDelta();
          }
          this.loading = false;
        }
        else {
          this.loading = false;
        }
        this.kpiSelectionDisabled = false;
        this.enableTaskBankTab();
      }
    })
  }
  loadData() {
    this.publishUpdates();
    //*******Resume old block if needed **** */
    // this.dataRepositoryService.removedRouteIds?.forEach(rmRouteIds => {
    //   this.removeDeselectedRoute(rmRouteIds);
    // });
  }
  removeDeselectedRoute(routeId: string): void {
    if (routeId) {
      this.removeRouteColor(routeId);
      [...this.dataRepositoryService.plannedTbCollection.values()].forEach(task => {
        if (task.route_id === routeId) {
          this.dataRepositoryService.plannedTbCollection.delete(task.task_id);
        }
      });

      //check if the route had task which were selected for task movement then those task should be removed from taskMovement list
      this.tbcom.setDataStream({data:routeId, type: TBDataSteamType.RemoveTask});
      
      /***NOTe separated below commented code and used publishUpdates() whereever needed
       * 
       */
      // this.setTaskBankCount(this.dataRepositoryService.plannedTbCollection, TBTabName.Planned);
      // if(this.dataRepositoryService.plannedTbCollection.size){
      //   this.dataAccessService.plannedRevised(true);
      //   // this.dataAccessService.plannedRevised([...this.dataRepositoryService.plannedTbCollection.values()], true);
      // }
      // else{
      //   this.dataAccessService.plannedRevised(true);
      //   // this.dataAccessService.plannedRevised([], true);
      //   this.setupDataStream([], TBDataSteamType.Reset);
      // }
    }
  }

  publishUpdates(){
    this.setTaskBankCount(
      this.dataRepositoryService.plannedTbCollection,
      TBTabName.Planned
    );
    if (this.dataRepositoryService.plannedTbCollection.size) {
      this.dataAccessService.plannedRevised(true);
      // this.dataAccessService.plannedRevised([...this.dataRepositoryService.plannedTbCollection.values()], true);
    } else {
      this.dataAccessService.plannedRevised(true);
      // this.dataAccessService.plannedRevised([], true);
      this.setupDataStream([], TBDataSteamType.Reset);
    }
  }
  
  /**
   * Method resets the planned task tab  
   */
  resetPlannedTab(){
    // this.plannedData = []
    this.dataAccessService.plannedRevised(true);
    this.selectedTabName = TBTabName.Unplannedpickup;
  }

  /**
   * Method creates a communication object which contains data to be communicated and type of action on that data
   */
  setupDataStream(data: any, actionType: string){
    const dataStream: CommunicationDataStream = {
      data: data,
      type: actionType
    }
    this.tbcom.setDataStream(dataStream)

  }

  /**
   * setTaskBankCount sets the count for each tab of Task Bank.
  */
  setTaskBankCount(taskCollection: any, tabName: string) {
    this.taskBankTabs.map(((tab: { label: any; size: any; })=>{
      if(tab.label.toLowerCase() === tabName.toLowerCase()) {
        tab.size = taskCollection.size;
      }
    }));
  }

  getUnplannedPickupData(selectedBusinessLocation: string) {
    this.loading = true;
    this.getUnplannedPickup(selectedBusinessLocation, "unplanned", this.dataRepositoryService.dateOffset, this.dataRepositoryService.unplannedTbEtag).pipe(take(1))
      .subscribe({
        next: (response: UnplannedResponse) => {
          this.loading = false;
          this.taskInfoFormatterService.uppTaskAdapter(response);
          this.dataRepositoryService.unplannedTbEtag = response.etag;
          this.setTaskBankCount(this.dataRepositoryService.unplannedTbCollection, TBTabName.Unplannedpickup);
          this.dataAccessService.unPlannedRevised([...this.dataRepositoryService.unplannedTbCollection.values()])
        },
        error: (error) => {
          this.loading = false;
          this.enableTaskBankTab();
        },
        complete: () => {
          if (this.dataRepositoryService.dateOffset === 0 && (this.tabSubscription?.closed || !this.tabSubscription)) {
            this.getTaskBankDelta();
          }
          else {
            this.loading = false;
          }
          this.enableTaskBankTab();
        }
      })
  }

  getPlannedPBMData(selectedBusinessLocation:string){
    this.loading = true;
      this.getPlannedPBM(selectedBusinessLocation, this.dataRepositoryService.dateOffset, this.dataRepositoryService.plannedPbmTbEtag).pipe(take(1))
        .subscribe({
          next: (response: IPlannedPBMTasksApiResponse) => {
            this.loading = false;
            this.taskInfoFormatterService.plannedPBMTaskAdapter(response);
            this.dataRepositoryService.plannedPbmTbEtag = response.etag;
            this.setTaskBankCount(this.dataRepositoryService.plannedPbmTbCollection, TBTabName.PlannedPBM);
            this.dataAccessService.pbmRevised([...this.dataRepositoryService.plannedPbmTbCollection.values()])
          },
          error: (error) => {
            this.loading = false;
            this.enableTaskBankTab();
          },
          complete: () => {
            if (this.selectedTabName.toLowerCase() === TBTabName.PlannedPBM.toLowerCase() && this.dataRepositoryService.dateOffset === 0 && (this.tabSubscription?.closed || !this.tabSubscription)) {
              this.getTaskBankDelta();
            }
            else {
              this.loading = false;
            }
            this.enableTaskBankTab();
          }
        })
  }

  /**
   * To Enable Task Bank 
   * After Receiving API response
   * for the tabs.
   */
  enableTaskBankTab() {
    let taskBankTabs = [...this.taskBankTabs]
    taskBankTabs.map(item =>{
      item.disabled = false;
    })
    this.taskBankTabs = taskBankTabs
  }

  getUnloadedData(selectedBusinessLocation:string){
    this.loading=true;
    this.getUnloaded(selectedBusinessLocation, this.dataRepositoryService.dateOffset, this.dataRepositoryService.unloadedTbEtag).pipe(take(1)).subscribe({
      next: (response: UnloadedResponse) => {
        this.loading = false;
        this.taskInfoFormatterService.unloadedTaskAdapter(response);
        this.dataRepositoryService.unloadedTbEtag = response.etag;
        this.setTaskBankCount(this.dataRepositoryService.unloadedTbCollection, TBTabName.Unloaded);
        this.dataAccessService.unloadedRevised([...this.dataRepositoryService.unloadedTbCollection.values()]);
      },
      error: (error) => {
        this.loading = false;
        this.enableTaskBankTab();
      },
      complete:()=>{
        if (this.dataRepositoryService.dateOffset === 0 && (this.tabSubscription?.closed || !this.tabSubscription)) {
          this.getTaskBankDelta();
        }
        else {
          this.loading = false;
        }
        this.enableTaskBankTab();
      }
    })
  }
  /**
   * 
   * Getting push to cloud data and calling api
   * @param selectedBusinessLocation selected business location data will fetch
   */
  getCloudData(selectedBusinessLocation:string){
    this.loading=true;
    this.getPushToCloud(selectedBusinessLocation, this.dataRepositoryService.dateOffset, this.dataRepositoryService.cloudTbEtag).pipe(take(1)).subscribe({
      next: (response: UnplannedResponse) => {
        this.loading = false;
        this.taskInfoFormatterService.cloudTaskAdapter(response);
        this.dataRepositoryService.cloudTbEtag = response.etag;
        this.setTaskBankCount(this.dataRepositoryService.cloudTbCollection, TBTabName.PushToCloud);
        this.dataAccessService.cloudRevised([...this.dataRepositoryService.cloudTbCollection.values()]);
      },
      error: (error) => {
        this.loading = false;
        this.enableTaskBankTab();
      },
      complete:()=>{
        if (this.dataRepositoryService.dateOffset === 0 && (this.tabSubscription?.closed || !this.tabSubscription)) {
          this.getTaskBankDelta();
        }
        else {
          this.loading = false;
        }
        this.enableTaskBankTab();

      }
    })
  }
  
  updateUnPlannedTaskMapModel(deltaUPPTasks: Map<string, IUPPTasksMap>, removed_tasks: string[]) {
    if (deltaUPPTasks.size) {
      deltaUPPTasks.forEach(deltaUPPTask => {
        let tbTaskObj = this.dataRepositoryService.unplannedTbCollection.get(deltaUPPTask.task_id);
        if (tbTaskObj) {
          tbTaskObj = deltaUPPTask;
          tbTaskObj.taskStateTab = TBTabName.Unplannedpickup;

          this.dataRepositoryService.unplannedTbCollection.set(deltaUPPTask.task_id, tbTaskObj);
        }
        else {
          //new entry
          this.dataRepositoryService.unplannedTbCollection.set(deltaUPPTask.task_id, deltaUPPTask);
        }
      });

    }
    if (removed_tasks?.length) {
      removed_tasks?.forEach(rmTask => {
        this.dataRepositoryService.unplannedTbCollection.delete(rmTask);
      })
    }
    const newTaskMap = new Map(this.dataRepositoryService.unplannedTbCollection.entries());
    this.dataRepositoryService.unplannedTbCollection = newTaskMap;
    this.setTaskBankCount(this.dataRepositoryService.unplannedTbCollection, TBTabName.Unplannedpickup);
    this.dataAccessService.unPlannedRevised([...this.dataRepositoryService.unplannedTbCollection.values()]);
  }

  updatePlannedTaskMapModel(deltaTasksMapObject: Map<string, ITasksMap>, removed_tasks: string[]) {
    if (deltaTasksMapObject.size) {
      //transform the list into groups, each group represents the route object and values within it repesents tasks
      let groups = this.taskInfoFormatterService.groupCollection([...deltaTasksMapObject.values()], 'route_id');
      //iterate through all groups
      groups?.forEach((groupRoute: any) => {
        //get the task list from group (route)
        let deltaGroupTasks = groupRoute.values ?? [];
        let isRouteUnselected = !this.dataRepositoryService.selectedRouteList.find((f: any) => f.route_id === groupRoute.key);

       //iterate through each task
        deltaGroupTasks.forEach((deltaTask: ITasksMap) => {
          if(isRouteUnselected){
            this.dataRepositoryService.plannedTbCollection.delete(deltaTask.task_id)
          }
          else{
            //update each route properties from taskMap 
            let tbTaskObj = this.dataRepositoryService.plannedTbCollection.get(deltaTask.task_id);
            //check if delta's incoming task_id is present in Task Bank collection or not
            if (tbTaskObj) {
              tbTaskObj = deltaTask;
  
              this.dataRepositoryService.plannedTbCollection.set(deltaTask.task_id, tbTaskObj);
            }
            else {
              this.dataRepositoryService.plannedTbCollection.set(deltaTask.task_id, deltaTask);
            }
          }
        });
        //sort the task for each group(route) level iteration 
        [...this.dataRepositoryService.plannedTbCollection.values()]
          .filter(f => f.route_id === groupRoute.key)
          .sort((a, b) => Number(a.stop_sequence) - Number(b.stop_sequence))

      });
    }
    if (removed_tasks?.length) {
      removed_tasks?.forEach(rmTask => {
        this.dataRepositoryService.plannedTbCollection.delete(rmTask);
      });
    }
    const newTaskMap = new Map(this.dataRepositoryService.plannedTbCollection.entries());
    this.dataRepositoryService.plannedTbCollection = newTaskMap;
    this.setTaskBankCount(this.dataRepositoryService.plannedTbCollection, TBTabName.Planned);
    this.dataAccessService.plannedRevised(true, [...deltaTasksMapObject.values()]);
  }

  updatePlannedPbmTaskMapModel(deltaPlannedPBMTasks: Map<string, IPlannedPBMTasksMap>, removed_tasks:string[]){
    if (deltaPlannedPBMTasks.size) {
      deltaPlannedPBMTasks.forEach(deltaPbmTask => {
        let tbTaskObj = this.dataRepositoryService.plannedPbmTbCollection.get(deltaPbmTask.task_id);
        if (tbTaskObj) {
          tbTaskObj = deltaPbmTask;
          tbTaskObj.taskStateTab = TBTabName.PlannedPBM;

          this.dataRepositoryService.plannedPbmTbCollection.set(deltaPbmTask.task_id, tbTaskObj);
        }
        else {
          //new entry
          this.dataRepositoryService.plannedPbmTbCollection.set(deltaPbmTask.task_id, deltaPbmTask);
        }
      });

    }
    if (removed_tasks?.length) {
      removed_tasks?.forEach(rmTask => {
        this.dataRepositoryService.plannedPbmTbCollection.delete(rmTask);
      })
    }
    const newTaskMap = new Map(this.dataRepositoryService.plannedPbmTbCollection.entries());
    this.dataRepositoryService.plannedPbmTbCollection= newTaskMap;
    this.setTaskBankCount(this.dataRepositoryService.plannedPbmTbCollection, TBTabName.PlannedPBM);
    this.dataAccessService.pbmRevised([...this.dataRepositoryService.plannedPbmTbCollection.values()])
  }
  updateUnloadedItemMapModel(deltaUnloadedItems: Map<string, IUnloadedTasksMap>, removed_tasks:string[]){
    if (deltaUnloadedItems.size) {
      deltaUnloadedItems.forEach(deltaUnloadedItem => {
        let tbTaskObj = this.dataRepositoryService.unloadedTbCollection.get(deltaUnloadedItem.item_id);
        if (tbTaskObj) {
          tbTaskObj = deltaUnloadedItem;

          this.dataRepositoryService.unloadedTbCollection.set(deltaUnloadedItem.item_id, tbTaskObj);
        }
        else {
          //new entry
          this.dataRepositoryService.unloadedTbCollection.set(deltaUnloadedItem.item_id, deltaUnloadedItem);
        }
      });

    }
    if (removed_tasks?.length) {
      removed_tasks?.forEach(rmTask => {
        this.dataRepositoryService.unloadedTbCollection.delete(rmTask);
      })
    }
    const newTaskMap = new Map(this.dataRepositoryService.unloadedTbCollection.entries());
    this.dataRepositoryService.unloadedTbCollection= newTaskMap;
    this.setTaskBankCount(this.dataRepositoryService.unloadedTbCollection, TBTabName.Unloaded);
    this.dataAccessService.unloadedRevised([...this.dataRepositoryService.unloadedTbCollection.values()])
  }
  /**
   * 
   * @param deltaCloudTasks mapping delta items of push to cloud
   * @param removed_tasks removing tasks from push to cloud
   */
  updateCloudTaskMapModel(deltaCloudTasks:any, removed_tasks:string[]){
      let newTaskMap = new Map();
      if (deltaCloudTasks.length) {
        deltaCloudTasks.forEach((deltaCloudTasks:any) => {
          // TODO: 
            // Backend is currently not handling delta so need to add everytime if delta comes as well
              // let tbTaskObj = this.dataRepositoryService.cloudTbCollection.get(deltaCloudTasks.task_id);
              // if (!!tbTaskObj) {
              //   tbTaskObj = deltaCloudTasks;

              //   this.dataRepositoryService.cloudTbCollection.set(deltaCloudTasks.task_id, tbTaskObj);
              // }
              // else {
              //   //new entry
              //   this.dataRepositoryService.cloudTbCollection.set(deltaCloudTasks.task_id, deltaCloudTasks);
              // }
          newTaskMap.set(deltaCloudTasks.task_id, deltaCloudTasks);
        });
    } else {
      this.dataRepositoryService.cloudTbCollection.clear();
    }
    // TODO: 
      // Backend is currently not handling delta so need to add everytime if delta comes as well
        // if (removed_tasks?.length) {
        //   removed_tasks?.forEach(rmTask => {
        //     this.dataRepositoryService.cloudTbCollection.delete(rmTask);
        //   })
        // }
        // const newTaskMap = new Map(this.dataRepositoryService.cloudTbCollection.entries());
    this.dataRepositoryService.cloudTbCollection= newTaskMap;
    this.setTaskBankCount(this.dataRepositoryService.cloudTbCollection, TBTabName.PushToCloud);
    this.dataAccessService.cloudRevised([...this.dataRepositoryService.cloudTbCollection.values()])
  }
  

  /**
    * getting task bank delta call on one minute interval time based on active tab
    */  
  getTaskBankDelta() {
    this.tabSubscription = interval(1 * 60 * 1000)
      .pipe(
        takeWhile((v) => this.dataRepositoryService.dateOffset === 0),
        mergeMap(() => this.handleActiveTab())
      )
      .subscribe({
        next: (response: any) => {
          this.processTabResponse(response);
        }
      });
  }
  /**
    * delta response processing only for selected tab
    */ 
  processTabResponse(response: any): void {
    const tabName = this.selectedTabName.toLowerCase();
   
    switch (tabName) {
      case TBTabName.Planned.toLowerCase():
        this.handlePlannedTab(response);
        this.enableTaskBankTab();
        break;
   
      case TBTabName.Unplannedpickup.toLowerCase():
        this.handleUnplannedPickupTab(response);
        this.enableTaskBankTab();
        break;
   
      case TBTabName.PlannedPBM.toLowerCase():
        this.handlePlannedPBMTab(response);
        this.enableTaskBankTab();
        break;
   
      case TBTabName.Unloaded.toLowerCase():
        this.handleUnloadedTab(response);
        this.enableTaskBankTab();
        break;

      case TBTabName.PushToCloud.toLowerCase():
        this.handleCloudTab(response);
        this.enableTaskBankTab();
        break;
    }
  }
  /**
    * handling delta response for planned tab
    */  
  handlePlannedTab(response: any) {
    this.dataRepositoryService.plannedTbEtag = response.etag;
    if (this.dataRepositoryService.selectedRouteList.length) {
      const mappedDeltaTasks = this.taskInfoFormatterService.taskDeltaAdaptArray(response);
      if (response?.tasks?.length || response?.removed_tasks?.length) {
        this.updatePlannedTaskMapModel(
          mappedDeltaTasks,
          response.removed_tasks
        );
      }
    } else {
      this.updatePlannedTaskMapModel(new Map<string, ITasksMap>(), []);
    }
  }
  /**
    * handling delta response for unplanned pickup tab
    */  
  handleUnplannedPickupTab(response: any) {
    this.dataRepositoryService.unplannedTbEtag = response.etag;
    const mappedUppDeltaTasks = this.taskInfoFormatterService.uppTaskAdapter(response);
    if (response?.tasks?.length || response?.removed_tasks?.length) {
      this.updateUnPlannedTaskMapModel(
        mappedUppDeltaTasks,
        response.removed_tasks
      );
    }
  }
  /**
    * handling delta response for planned pbm tab
    */
  handlePlannedPBMTab(response: any) {
    this.dataRepositoryService.plannedPbmTbEtag = response.etag;
    const mappedPbmDeltaTasks = this.taskInfoFormatterService.plannedPBMTaskAdapter(response);
    if (response?.tasks?.length || response?.removed_tasks?.length) {
      this.updatePlannedPbmTaskMapModel(
        mappedPbmDeltaTasks,
        response.removed_tasks
      );
    }
  }
  /**
    * handling delta response for unloaded tab
    */
  handleUnloadedTab(response: any) {
    this.dataRepositoryService.unloadedTbEtag = response.etag;
    const mappedUnloadedDeltaItems = this.taskInfoFormatterService.unloadedTaskAdapter(response);
    if (response?.items?.length || response?.removed_items?.length) {
      this.updateUnloadedItemMapModel(
        mappedUnloadedDeltaItems,
        response.removed_items
      );
    }
  }
  /**
    * handling delta response for push to cloud tab
    */
  handleCloudTab(response: any) {
    this.dataRepositoryService.cloudTbEtag = response.etag;
    const mappedCloudDeltaTasks = this.taskInfoFormatterService.cloudTaskAdapter(response);
    //TODO: to keep the line once delta issue will be resolved 
    // by Backend team
    //if (response?.tasks?.length || response?.removed_tasks?.length) {
      this.updateCloudTaskMapModel(
        mappedCloudDeltaTasks,
        response.removed_tasks
      );
    //}
  }
  /**
    * handling active tab subscription
    */
  activeTabSubscription(){
    this.handleActiveTab().subscribe({
      next: (response: any) => {
        this.processTabResponse(response);
      }
    });
  }

  handleActiveTab(): Observable<any> {   
    if(this.router.url === Navigation.Dashboard || this.router.url === Navigation.MapLeaflet) {
      switch (this.selectedTabName.toLowerCase()) {
        case TBTabName.Planned.toLowerCase():{
          const routeIds = this.dataRepositoryService.selectedRouteList.map((x: any) => x.route_id);
          if (routeIds.length > 0) {
            return this.getPlannedDeltaResponse(routeIds.toString());
          } else {
            this.enableTaskBankTab();
            return EMPTY;
          }
        }
        case TBTabName.Unplannedpickup.toLowerCase():
          return this.getUnplannedPickupDelta();
        case TBTabName.PlannedPBM.toLowerCase():
          return this.getPlannedPBMDelta();
        case TBTabName.Unloaded.toLowerCase():
          return this.getUnloadedDelta();
        case TBTabName.PushToCloud.toLowerCase():
          return this.getCloudDelta();
        default:
          return EMPTY;
      }
    }
    return EMPTY;
  }

  switchTAB(event: any) {
    const selectedTab:any = event.label;
    const apiCall = event.initalApiCall;
    this.taskBankTabs.map(item => {
      item.disabled = true;
    });
    this.selectedTabName = selectedTab;
    this.activeIndex = event;
    this.dataAccessService.onTBTabSwitched(true);
    this.dataRepositoryService.taskMovementData = {} as TaskMoveCommand;
    this.dataRepositoryService.pullBacktaskMovementData = {} as PullBackTaskCommand;
    this.dataRepositoryService.movingTasksMap.clear();
    if (selectedTab.toLowerCase() === TBTabName.Planned.toLowerCase()) { 
        this.activeTabSubscription();
    } else if (selectedTab.toLowerCase() === TBTabName.Unplannedpickup.toLowerCase()) {
          this.activeTabSubscription();
    } else if (selectedTab.toLowerCase() === TBTabName.PlannedPBM.toLowerCase()) {
      if (!apiCall) {
        event.initalApiCall = true;
        this.getPlannedPBMData(this.dataRepositoryService.selectedBusinessLocation)
      }else{
        this.activeTabSubscription();
      }
    }
    else if (selectedTab.toLowerCase() === TBTabName.Unloaded.toLowerCase()) {
      if (!apiCall) {
        event.initalApiCall = true;
        this.getUnloadedData(this.dataRepositoryService.selectedBusinessLocation);
      }else{
        this.activeTabSubscription(); 
      }
    }
    else if (selectedTab.toLowerCase() === TBTabName.PushToCloud.toLowerCase()) {
      if (!apiCall) {
        event.initalApiCall = true;
        this.getCloudData(this.dataRepositoryService.selectedBusinessLocation);
      }else{
        this.activeTabSubscription(); 
      }
    }

    //check if the router page is map-leaflet 
    if(this.router.url===Navigation.MapLeaflet && selectedTab.toLowerCase() !== TBTabName.Planned.toLowerCase()){
      const routeIds = this.dataRepositoryService.selectedRouteList.map((x: any) => x.route_id);
      if (routeIds.length > 0) {
        this.dataAccessService.plannedDataInMap(true);
      }
    }
  
  }

  /*  
    This method is called when task movement is done from planned or unplanned tab.
    Tasks will be updated in planned tab according to the movement.
  */
  getPlannedDeltaOnce() {
    const routeIds = this.dataRepositoryService.selectedRouteList.map((x: any) => x.route_id);
    this.getPlannedDeltaResponse(routeIds.toString())
      .pipe(takeLast(1))
      .subscribe({
        next: (response: any) => {
          this.handlePlannedTab(response);
        }
      });
  }
  /*  
    This method is called when task movement is done from planned pbm.
    Tasks will be updated in Unplanned pickup tab according to the movement.
  */
  getUnplannedPickupDeltaOnce() {
    this.getUnplannedPickupDelta()
      .pipe(takeLast(1))
      .subscribe({
        next: (response: any) => {
          this.handleUnplannedPickupTab(response);
        }
      });
  }
  /*  
    This method is called when task movement is done from unplanned pickup.
    Tasks will be updated in plannedpbm tab according to the movement.
  */
  getPlannedPBMDeltaOnce() {
    this.getPlannedPBMDelta()
      .pipe(takeLast(1))
      .subscribe({
        next: (response: any) => {
          this.handlePlannedPBMTab(response);
        }
      });
  }

  getPlanned(routeId: string, offset: number, etag: number, Event: boolean): Observable<TaskInfoResp> {
    return this.connectionService.httpReq('get', `tasks?routeid=${routeId}&dayoffset=${offset}&delta=false`,'dp', '', etag, false, false)
  }
  getPlannedDeltaResponse(routeId: string): Observable<TaskInfoResp> {
    let offset = this.dataRepositoryService.dateOffset;
    let etag = this.dataRepositoryService.plannedTbEtag
    return this.connectionService.httpReq('get', `tasks?routeid=${routeId}&dayoffset=${offset}&delta=true`,'dp', '', etag, false, false);
  }
  getUnplannedPickup(BLID: string, status: string, offset: number, etag: number): Observable<UnplannedResponse> {
    return this.connectionService.httpReq('get', `tasks/unplanned?blid=${BLID}&status=${status}&daysoffset=${offset}&delta=false`, 'dp','', etag, false, false);
  }
  getUnplannedPickupDelta(): Observable<UnplannedResponse> {
    let BLID = this.dataRepositoryService.selectedBusinessLocation;
    if(this.selectedUnplannedFavList?.id !== 'default') {
      BLID = this.selectedUnplannedFavList?.blids.toString();
    }
    let status = "unplanned";
    let offset = this.dataRepositoryService.dateOffset
    let etag = this.dataRepositoryService.unplannedTbEtag
    return this.connectionService.httpReq('get', `tasks/unplanned?blid=${BLID}&status=${status}&daysoffset=${offset}&delta=true`,'dp', '', etag, false, false);
  }
  
  getPlannedPBM(BLID:string,offset:number, etag: number):Observable<IPlannedPBMTasksApiResponse>{
  return this.connectionService.httpReq('get', `tasks/planned?blid=${BLID}&daysoffset=${offset}&delta=false`, 'dp','', etag, false, false);
  }
  getPlannedPBMDelta():Observable<IPlannedPBMTasksApiResponse>{
    let BLID = this.dataRepositoryService.selectedBusinessLocation;
    if(this.selectedPlannedFavList.id !== 'default') {
      BLID = this.selectedPlannedFavList.blids.toString();
    }
    let offset = this.dataRepositoryService.dateOffset
    let etag = this.dataRepositoryService.plannedPbmTbEtag
    return this.connectionService.httpReq('get', `tasks/planned?blid=${BLID}&daysoffset=${offset}&delta=true`, 'dp','', etag, false, false);
  }

  getUnloaded(BLID: string, offset: number, etag: number): Observable<UnloadedResponse> {
    return this.connectionService.httpReq('get', `items/unloaded?daysoffset=${offset}&delta=false&blid=${BLID}`, 'dp', '', etag, false, false)
  }
  /**
   * 
   * @param BLID sending selected business location id
   * @param offset sending dateoffset
   * @param etag 
   * @returns calling get api of push to cloud with delta false
   */
  getPushToCloud(BLID: string, offset: number, etag: number): Observable<UnplannedResponse> {
    return this.connectionService.httpReq('get', `tasks/parked?blid=${BLID}&daysoffset=${offset}&delta=false`, AppConstants.DP, '', etag, false, false);
  }

  getUnloadedDelta(): Observable<UnloadedResponse> {
    let BLID = this.dataRepositoryService.selectedBusinessLocation;
    let offset = this.dataRepositoryService.dateOffset;
    let etag = this.dataRepositoryService.unloadedTbEtag;
    return this.connectionService.httpReq('get', `items/unloaded?daysoffset=${offset}&delta=true&blid=${BLID}`, 'dp', '', etag, false, false)
  }
  /**
   * 
   * @returns calling get api of push to cloud with delta true
   */
  getCloudDelta(): Observable<UnplannedResponse> {
    let BLID = this.dataRepositoryService.selectedBusinessLocation;
    let offset = this.dataRepositoryService.dateOffset;
    let etag = 0; // this.dataRepositoryService.cloudTbEtag; // TODO: Backend needs to change the delta push to cloud until then Frontend will be passing 0 at the time of delta call as well.
    return this.connectionService.httpReq('get', `tasks/parked?daysoffset=${offset}&delta=false&blid=${BLID}`, AppConstants.DP, '', etag, false, false)
  }

 

  /**
   * To get the User Favourite List set in preference.
   */
  getFavList(){
    const queryParams:any = {
      type:'business_locations'
    }
      this.connectionService.httpReq('get', `user/preferences`,'v4',queryParams,'',false,false).subscribe(response =>{
        if(response?.business_locations) {
          const blList = JSON.parse(response.business_locations);
          if(blList.length > 0) {
            this.favBLStruct(blList.filter((x:any) => x.fav !== 'evening'));
          } else {
            this.setDefaultOptionOnFavList();
          }
        } else {
          this.setDefaultOptionOnFavList();
        }
      })
    }

    /**
     * To format the BLID Since we are getting 
     * the BLIDs only from API response to show the Business Location Name 
     * on Hover List Name option we need to format the data
     * @param favList Fav List
     */
    favBLStruct(favList: FavouriteListAPIModel[]) {
      let tempFavList: FavouriteListModel[] = [];
      const businessLocation:any = [...(this.dataRepositoryService.businessLocationListMap).values()]
      favList?.map((fav: FavouriteListAPIModel, index:number) => {
        let blIdsWithName: any = [];
        let blName:any = [];
        fav?.blids?.map((blId: any) => {
          const find = businessLocation.find((x: any) => x.business_location_id ===  blId);
          if (find !== undefined) {
            blIdsWithName.push({ name: find.name, id: find.business_location_id, isSelected: false });
            blName.push(find.name);
          }
        });
        tempFavList.push({ 
          name: fav.name, favBloms: blIdsWithName, 
          id: fav.id, selected: false,
          blids: fav.blids,
          blName: blName.join()});
      });
      this.setDefaultOptionOnFavList(tempFavList);
    }

    /**
     * Use to set the default option in Fav List dropdown
     * @param favList optional oarameter to use if we have list added in preferences
     */
    setDefaultOptionOnFavList(favList:any=[]) {
      const defaultList: FavouriteListModel = { name: 'Default', favBloms: [], id: 'default', selected: false, blName:'', blids:[] };
      favList.push(defaultList);
      this.favListResetToDefaultBusinessLocation();
      this.BlFavList = favList.reverse();
    }

    favListResetToDefaultBusinessLocation() {
      const defaultList: FavouriteListModel = { name: 'Default', favBloms: [], id: 'default', selected: false, blName:'', blids:[] };
      this.selectedUnplannedFavList = defaultList;
      this.selectedPlannedFavList = defaultList;
    }

    onDropdownChange($event:any){
      if($event.value) {
        if(this.selectedTabName.toLowerCase() === TBTabName.Unplannedpickup.toLowerCase()){
          //clear the task movement selection 
          this.dataAccessService.onFavBLChanged(true);
          this.dataRepositoryService.unplannedTbCollection.clear();
          if($event.value.id === 'default') {
            this.getUnplannedPickupData(this.dataRepositoryService.selectedBusinessLocation);
          } else {
            this.getUnplannedPickupData($event.value.blids.toString());
          }
        }else if (this.selectedTabName.toLowerCase() === TBTabName.PlannedPBM.toLowerCase()){
          this.dataAccessService.onFavBLChanged(true);
          this.dataRepositoryService.plannedPbmTbCollection.clear();
          if($event.value.id === 'default') {
            this.getPlannedPBMData(this.dataRepositoryService.selectedBusinessLocation);
          } else {
            this.getPlannedPBMData($event.value.blids.toString());
          }
        }
      }  
    }
    
  editPickupOrder(row: any) {
    let bl: any = localStorage.getItem(LocalStorage.BusinessLocation);
    let order = new EditPBMOrder();
    order.orderNum = row.pbm_order_id;
    order.viewType = this.editPBMOrderViewType;
    order.businessLocation = {
      id: this.selectedTabName === TBTabName.Planned ? this.dataRepositoryService.selectedBusinessLocation : row.business_location_id
      , description: this.selectedTabName === TBTabName.Planned ? this.dataRepositoryService.businessLocationListMap.get(JSON.parse(bl))?.name ?? '' : row.bl_name
    };
    this.editPBMOrder(order);
  }

  editPBMOrder(orderObj: any) {
    window.open(`${environment.pbmUrl}/#/fromInternalApp?data=${encodeURIComponent(JSON.stringify(orderObj))}`, `_blank`);
  }

  removeRouteColor(route_id: string){
    this.dataRepositoryService.routesColour?.delete(route_id);
  }


  /**
   * Methods for Address operations add/edit
   */

  /**
   * Method opens the popup of address update
   */
  showDialog(task_id: string){
    this.updateAddressPopup.updateAddressTaskId = task_id;
    this.updateAddressPopup.showAddressPopup = true;
    // this.dialogRef = this.dialogService.open(UpdateAddressComponent,{
    //   data: {
    //     task_id: '12313113123'
    //   },
    //   header: 'Address',
    //   width: '70%',
    //   height: '80%',
    //   contentStyle: { overflow: 'auto' },
    //   baseZIndex: 10000,
    //   maximizable: true
    // })

    // this.dialogRef.onClose.subscribe((result: any)=>{
    //   if(result.address_id){
    //     }
    // })
  }

  closeDialog(event: boolean){
    if(event){
      this.updateAddressPopup.showAddressPopup = false;

    }

  }

  /**
   * Map view and task bank task highlighting communication subscriptions and methods 
   * 
   */

  /**
   * 
   * @param data type {isSelectedFromMap: boolean, taskId: string}
   */
  onSelectCoordinates(data: { isSelectedFromMap: boolean, taskId: string, routeId?: string }) {
    const { isSelectedFromMap, taskId, routeId } = data;
    this.isSelectedFromMap.next({
      isSelectedFromMap,
      taskId,
      routeId
    });
  }

  /**
   * 
   * @param data type {isSelectedFromPlannedTable: boolean, taskId: string}
   */
  onSelectTaskFromPlannedTable(data:{isSelectedFromPlannedTable:boolean, taskId:string, routeId?: string}) {
    const {isSelectedFromPlannedTable, taskId, routeId} = data;
    this.isSelectedFromPlannedTable.next({
      isSelectedFromPlannedTable,
      taskId,
      routeId
    });
  }

}