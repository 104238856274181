import { InjectionToken } from "@angular/core";

export class BusinessLocation {
    business_location_id: string='';
    name: string='';
    country_code: string='';
}

export class BusinessLocationDetails {
    business_locations : BusinessLocation[] = [];
}

export interface PopoutData {
    modalName: string;
    businessLoc: BusinessLocation;
  }
  
  export const POPOUT_MODAL_DATA = new InjectionToken<PopoutData>('POPOUT_MODAL_DATA');
  
  export enum PopoutModalName {
    'kpimain' = 'KPI_MAIN'
  }


//Enumertations of IndexDB stores 
export enum IDBStore{
  BLInfo = 'BLInfo',
  MapRoutesInfo = 'MapRoutesInfo',
  RoutesColorCodeInfo = 'RoutesColorCodeInfo',
  blCollection = 'blCollection',
  blFavList = 'blFavList',
  deviceInfo = 'deviceInfo',
  loggedInUserInfo = 'loggedInUserInfo',
  pushMsg = 'pushMsg',
  routeBundleInfo = 'routeBundleInfo',
  routeInfo = 'routeInfo',
  taskInfo = 'taskInfo',
  taskMoveInfo = 'taskMoveInfo',
  unloadedInfo = 'unloadedInfo',
  unplannedPickupInfo = 'unplannedPickupInfo',
  unplannedTasksInfo = 'unplannedTaksInfo',
  blomRoutes = 'blomRoutes',
  favRouteList = 'favRouteList',
  plannedPBM = 'plannedPBM'
}

//business location code symbols
export enum BLCode{
  SE = 'SE',
  DK = 'DK'
}

//Enumeration of Route State
export enum RouteState {
  Sorting ='sorting',
  Planning = 'planning',
  Loading = 'loading',
  Onroute = 'onroute',
  Finishloading =  'finishloading',
  Prepareroute =  'prepareroute',
  Manualplanning = 'manualplanning',
  Cancelled = 'cancelled',
  Unknown = 'unknown',
  Completed = 'completed'
}

export enum GanttRouteState{
  AllRoutes = 'allroutes',
  Sorting = 'sorting',
  Loading = 'loading',
  OnRoute = 'onroute'
}

export enum RouteType{
  Hub= 'hub',
  day = 'DAY',
  evening = 'EVENING',
  night = 'NIGHT',
}

export enum SourceAPI{
  RouteInfoAPI = 'routeinfo',
  PlanedTasksAPI = 'planedtasks',
  UnplannedTasksAPI = 'unplannedtasks',
  PlannedPBMTasksAPI = 'plannedpbmtasks',
}

//Enumeration of Route State BG color
export enum RouteBGColor{
  Sorting = '#00a0d6',
  Planning = '#269FC7',
  Onroute = '#BC6550',
  Loading = '#D8A356',
  Completed = '#198754',
  PrepareRoute = '#ff3300',
  Finishloading = '#cc99ff'
}

//Enumeration of Route State BG color
export enum GanttRowBGColor{
  Sorting = "#D7E8D5",
  Planning = "#CEEDF7",
  Onroute = "#F5DBD4",
  Loading = '#FCEBD2'
}

export enum KPIColumnName{
  Stops = "Stops",
  Tasks = "Tasks",
  Items = "Items",
  Taskh = 'tasks_pr_hour', 
  Weight = 'weight', 
  Volume = 'volume',
  RouteSelectionChecked = 'checked', 
  BreachedWarning = 'breachedWarning', 
  PendingActions = 'pendingActions', 
  filterfunnel = 'filterfunnel',
  RouteName = 'route_label', 
  RouteStatus = 'route_state',
  BusinessLocation = "bl_name",
}

export enum DisplayMeasuringUnit{
  Pixcel = 'px',
  Perecentage = '%'
}
//Enumeration of Color Legends in Gantt Section
export enum ColorLegends {
  failed = 'Failed',
  completed = 'Completed',
  yetToStart = 'Yet To Start',
  localTask = 'Local Task',
  timeWindowBreach = 'Time Window Breached / About To Breach'
}

//Enumeration of Task State
export enum TaskState {
  Planned = 'planned',
  Completed = 'completed',
  Notacted = 'notacted',
  Failed = 'failed',
  Unplanned = 'unplanned',
  Rejected = 'rejected',
  Requested = 'requested',
  Loaded = 'loaded',
  Onroute = 'onroute',
  Cancelled = 'cancelled',
  Unknown = 'unknown',
  Accepted = 'accepted',
  Unloaded = 'Unloaded' 
}

export enum TaskBGColor{
  Planned = '#00a0d6',
  Failed = '#FFD6D6',
  Completed ='#59B54F',
  Unplanned='#FF5F1F',
  Loaded='#8CE6B9',
  Unloaded='#8CE6B9',
  Requested='#FF5F1F',
  Notacted='#cd5172',
  Rejected='#FF0000'
}

//Enumeration of Item State
export enum ItemState{
  Loaded = 'loaded',
  Unloaded = 'unloaded',
  Delivered = 'Delivered',
  FailedAttempt = 'FailedAttempt',
  Pickedup = 'pickedup', 
  Unplanned = 'unplanned',
  Planned = 'planned',
  YetToBeDelivered ='YetToBeDelivered',
  Unknown = 'unknown'
}

//Emumeration of Task Type
export enum TaskType{
  Emptymailbox = 'emptymailbox',
  Visit = 'visit',
  Deliver = 'deliver',
  Pickup = 'pickup',
  Load = 'load',
  Unload = 'unload',
  Myboxpickup = 'myboxpickup',
  Handover = 'handover',
  Break = 'break',
  Nonproduct = 'nonproduct',
  Unknown = 'unknown'
}

export enum TaskSource{
  PBM = 'pbm',
  EDI = 'edi',
  CSP = 'csp'
}


//Route update for Component Types
export enum ComponentType{
  TaskbankComponent = 'TaskbankComponent',
  MapComponent = 'MapComponent',
  KPIComponent = 'KPIComponent',
  GanttComponent = 'GanttComponent'
}

export enum ComponentName{
  MaintTBComponent = 'MainTBComponent',
  DashboardTBComponent = 'DashboardTBComponent',
  MainMapComponent = 'MainMapComponent',
  DashboardMapComponent = 'DashboardMapComponent',
  MainKPIComponent = 'MainKPIComponent',
  DashboardKPIComponent = 'DashboardKPIComponent',
  GanttComponent = 'GanttComponent'
}

// Enum of Taskbank Tab Name 
export enum TBTabName{
  Planned = 'Planned',
  Unplanned = 'Unplanned',
  Unplannedpickup = 'Unplanned Pickup',
  Unloaded = 'Unloaded',
  UnloadedOld = 'Unloaded Old',
  PlannedPBM = 'Planned PBM',
  PushToCloud= 'Push To Cloud'
}

export enum ColumnName{
  Planned = 'Planned',
  Unplannedpickup = 'Unplanned Pickup',
  Unloaded = 'Unloaded',
  UnloadedOld = 'Unloaded Old',
  PlannedPBM = 'Planned PBM',
  PushToCloud= 'Push To Cloud'
}

//Enum used for storage event keys 
export enum StorageEvents{
  Routes = 'Routes',
  BLChanged = 'BLChanged',
  SelectAllRoute = 'selectAllRoute',
  UpdateOnRoute = 'updateOnRoute',
  AdhocTaskUpdate = 'adhocTaskUpdate',
  TaskFilter = 'taskFilter',
  PreviousDateSelection = 'previousDateSelection',
  NotifyTBTab = 'notifyTBTab',
  BLOMAddUpdate = 'isBLOMAddUpdate',
  refreshMap = 'refreshMap'
}

//Enum used for storage storage keys 
export enum LocalStorageEvents{
  ClickSettingsBack = 'clickSettingsBack',
  TaskFilter = 'taskFilter'
}
//Enum used for storage event keys 
export enum LocalStorageEventsValue{
  True = 'True',
  False = 'False'
}

//Enum used for session storage keys 
export enum SessionStorageKey{
  routeId = 'route_Id'
}

export enum TBResetAction{
  TaskMovement = 'taskmovement',
  ETABreachFilter = 'etabreachfilter'
}

export enum ResponseType {
  body = 'body',
  response = 'response'
}

export enum URLType {
  v4 = 'v4',
  dp = 'DP',
  pbm = 'PBM',
  air= 'AIR',
  osmDK= 'OSMDK',
  osmSE = 'OSMSE',
  ndmDev = "NDMDEV"
}

//business location code symbols
export enum SearchComponent {
  containerDetails = 'containerDetails',
  routeSummary = 'routeSummary',
  routeDetails = 'routeDetails',
  taskBank = 'taskBank',
  containerInfo = 'containerInfo',
  barcode = 'barcode',
  consignee = 'consignee',
  productcode = 'productcode'
}

//enumeration for user preference setting page
export enum UserPrefBLListType{
  eveningRoute = 'Evening Route',
  taskbankUPP = 'Task Bank - Unplanned Pickup'
}

export enum FilterIcon {
  applied = 'applied-filter',
  remove = 'remove-filter',
  partial = 'partial-filter'
}
// Start of model object for main Route Page //
export interface RouteDataInfo {
  stop_id:string;
  srno:number;
  stopName:string;
  tasks:RTask[];
}
export interface RTask {
  taskIndex:number;
  taskType: string;
  state?: string;
  timeWindow?: string;
  promisedata?: any;
  taskId?: string;
  taskPointName?: string;
  parcelRouteName?: string;
  parcelRouteId?: string;
  vapRouteName?: string;
  vapRouteId?: string;
  vapSeqNo?: string;
  dlaName?: string;
  dlaId?: string;
  deliveryPointType?: string;
  pamId?: string;
  addrLookup?: string;
  inlineAddr?: string;
  taskDuration?: string;
  servicePointName?: string;
  servicePointId?: string;
  doorDelivery?: string;
  receiverFlex?: string;
  items:RItem[]
}
export interface RItem {
  taskType:string;
  taskPointName:string;
  isRemoved:string;
  taskState:string;
  item_id:string;
  pn_item_id:string;
  item_type?:string;
  item?:any[];
  default_service_point_name?:string;
}

export interface ContainerDetails {
  item_id:string;
  route_id:string;
  route_label:string;
  bl_name:string;
  stop_name:string;
  product_code:string;
  task_id:string;
  item_state:string;
  additional_service_codes:string[];
  pn_item_id:string;
  task_point_name:string;
}

export enum LogEvent {
  businessLocationAPI = 'BusinessLocationAPI',
  routeInfoAPI = 'RouteInfoAPI',
  routeInfoDeltaAPI = 'RouteInfoDeltaAPI',
  taskAPI = 'TaskAPI',
  taskDeltaAPI = 'TaskDeltaAPI',
  plannedPBMAPI = 'PlannedPBMAPI',
  plannedPBMDeltaAPI = 'PlannedPBMDeltaAPI',
  unplannedPBMAPI = 'UnplannedPBMAPI',
  unplannedPBMDeltaAPI = 'UnplannedPBMDeltaAPI'
}

export enum NotesTypeField {
  driver = 'driverInstructions',
  manager = 'transportInstructions'
}
export class EditPBMOrder{
  viewType: string;
  orderNum: string;
  businessLocation: {
    id: string,
    description: string
  }
}
/*** End of model object for main Route Page ****/