import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataFormatService {
    constructor() { }

    /**
     * Sorting the input collection by specific property 
     */

    sortByProperty(list: any[], prop: string) {
        return list?.sort((index1: any, index2: any) => {
            let f = index1[prop]?.toLowerCase();
            let s = index2[prop]?.toLowerCase();
            return (f < s) ? -1 : (f > s) ? 1 : 0;
        })
    }
    
}