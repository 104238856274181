import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { DividerModule } from 'primeng/divider';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ChipModule } from 'primeng/chip';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { ActionbarComponent } from './components/actionbar/actionbar.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ConnectionService } from './services/connection/connection.service';
import { SingleSelectDropdownComponent } from './components/single-select-dropdown/single-select-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { FilterComponent } from './components/filter/filter.component';
import { TaskInfoFormatterService } from './services/task-info/task-info-formatter.service';
import { ResponseDataFormatService } from './services/format/response-data-format.service';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { CommonService } from './services/common/common.service';
import { DateFormatPipe } from './pipe/date-format-pipe/date-format.pipe';
import { MenuModule } from 'primeng/menu';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { KpiRouteStatePipe } from './pipe/kpi-route-state/kpi-route-state.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { KpicountPipePipe } from './pipe/taskbank-footer/kpicount-pipe.pipe';
import { TaskStatePipe } from './pipe/task-state-pipe/task-state-pipe';
import { CreateRouteComponent } from './components/create-route/create-route.component';

import { TaskSelectPipe } from './pipe/task-select/task-select-pipe.pipe';
import { AngularSplitModule } from 'angular-split';
import { TaskBankComponent } from '../modules/home/dashboard/task-bank/task-bank.component';
import { UnplannedComponent } from '../modules/home/dashboard/task-bank/unplanned/unplanned.component';
import { UnloadedComponent } from '../modules/home/dashboard/task-bank/unloaded/unloaded.component';
import { PlannedPbmComponent } from '../modules/home/dashboard/task-bank/planned-pbm/planned-pbm.component';
import { PlannedComponent } from '../modules/home/dashboard/task-bank/planned/planned.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { UpdateAddressComponent } from './components/add-address/update-address.component';
import { UpdateAddressLookupComponent } from './components/address-lookup/update-address-lookup.component';
import { ColumnVisibilityComponent } from './components/column-visibility/column-visibility.component';
import { ErrorWindowComponent } from './components/error-window/error-window.component';
import { RouteItemDetailsComponent } from './components/route-item-details/route-item-details.component';

import { ItemDetailsComponent } from '../modules/home/dashboard/task-bank/item-details/item-details.component';
import { TaskbankColumnVisibilityComponent } from '../modules/home/dashboard/taskbank-column-visibility/taskbank-column-visibility.component';
import { PushToCloudComponent } from '../modules/home/dashboard/task-bank/push-to-cloud/push-to-cloud.component';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ShowVehicleIconPipe } from './pipe/map-view/show-vehicle-icon.pipe';
@NgModule({
  declarations: [
    SideMenuComponent,
    ActionbarComponent,
    LoaderComponent,
    SingleSelectDropdownComponent,
    MultiSelectDropdownComponent,
    FilterComponent,
    UpdateAddressComponent,
    UpdateAddressLookupComponent,
    KpiRouteStatePipe,
    ShowVehicleIconPipe,
    KpicountPipePipe,
    TaskStatePipe,
    CreateRouteComponent,
    TaskSelectPipe,
    DateFormatPipe,
    ColumnVisibilityComponent,
    ErrorWindowComponent,
    RouteItemDetailsComponent,
    TaskBankComponent,
    UnplannedComponent,
    UnloadedComponent,
    PlannedPbmComponent,
    ItemDetailsComponent,
    PlannedComponent,
    TaskbankColumnVisibilityComponent,
    PushToCloudComponent
  ],
  imports: [
    CommonModule,
    CheckboxModule,
    MessagesModule,
    CardModule,
    InputTextModule,
    ToastModule,
    ConfirmDialogModule,
    TableModule,
    ButtonModule,
    TabViewModule,
    ToolbarModule,
    DividerModule,
    CalendarModule,
    RadioButtonModule,
    ChipModule,
    FormsModule,
    DialogModule,
    DropdownModule,
    NgxBarcode6Module,
    MultiSelectModule,
    ReactiveFormsModule,
    MenuModule,
    TooltipModule,
    TabMenuModule,
    InputSwitchModule,
    AutoCompleteModule
  ],
  exports: [
    CheckboxModule,
    MessagesModule,
    CardModule,
    InputTextModule,
    ToastModule,
    ConfirmDialogModule,
    TableModule,
    ButtonModule,
    TabViewModule,
    ToolbarModule,
    DividerModule,
    CalendarModule,
    RadioButtonModule,
    ChipModule,
    SideMenuComponent,
    ActionbarComponent,
    LoaderComponent,
    SingleSelectDropdownComponent,
    MultiSelectDropdownComponent,
    FilterComponent,
    UpdateAddressComponent,
    UpdateAddressLookupComponent,
    ColumnVisibilityComponent,
    RouteItemDetailsComponent,
    DialogModule,
    DropdownModule,
    NgxBarcode6Module,
    FormsModule,
    CommonModule,
    MultiSelectModule,
    ReactiveFormsModule,
    KpiRouteStatePipe,
    ShowVehicleIconPipe,
    MenuModule,
    TooltipModule,
    KpicountPipePipe,
    TaskStatePipe,
    CreateRouteComponent,
    TaskSelectPipe,
    DateFormatPipe,
    AngularSplitModule,
    TaskBankComponent,
    UnplannedComponent,
    UnloadedComponent,
    TaskbankColumnVisibilityComponent,
    PlannedPbmComponent,
    ItemDetailsComponent,
    PlannedComponent,
    TabMenuModule,
    InputSwitchModule,
    PushToCloudComponent
  ],
  providers: [
    ConnectionService,
    TaskInfoFormatterService,
    TitleCasePipe,
    ResponseDataFormatService,
    CommonService,
    DateFormatPipe,
    TaskSelectPipe
  ]
})
export class SharedModule { }
