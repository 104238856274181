import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { BrowserConfigurationAuthError, InteractionType, StringUtils, UrlString } from '@azure/msal-browser';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { MSALInterceptorConfigFactory } from 'src/app/app.module';
import { Location } from '@angular/common';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  msalInterceptorConfig: MsalInterceptorConfiguration;
  constructor(
    private authService: MsalService,
    private location: Location
  ) {
    this.msalInterceptorConfig = MSALInterceptorConfigFactory();
  }

   /**
   * Looks up the scopes for the given endpoint from the protectedResourceMap
   * @param endpoint Url of the request
   * @param endpoint Http method of the request
   * @returns Array of scopes, or null if not found
   *
   */
  
  getScopesForEndpoint(endpoint: string, httpMethod: string) {
    this.authService.getLogger().verbose("Interceptor - getting scopes for endpoint");
    const normalizedEndpoint = this.location.normalize(endpoint);
    const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys());
    const matchingProtectedResources = this.matchResourcesToEndpoint(protectedResourcesArray, normalizedEndpoint);
    if (matchingProtectedResources.length > 0) {
      return this.matchScopesToEndpoint(this.msalInterceptorConfig.protectedResourceMap, matchingProtectedResources, httpMethod);
    }
    return null;
}

/**
   * Finds resource endpoints that match request endpoint
   * @param protectedResourcesArray
   * @param endpoint
   * @param location
   * @returns
   */
matchResourcesToEndpoint(protectedResourcesEndpoints: any[], endpoint: string) {
  return protectedResourcesEndpoints.filter(key => {
    const normalizedKey = this.location.normalize(key);
    // Normalized key should include query strings if applicable
    const keyComponents = new UrlString(key).getUrlComponents();
    const relativeNormalizedKey = keyComponents.QueryString ? `${keyComponents.AbsolutePath}?${keyComponents.QueryString}` : this.location.normalize(keyComponents.AbsolutePath);
    // Relative endpoint not applicable, matching endpoint with protected resource. StringUtils.matchPattern accounts for wildcards
    if (relativeNormalizedKey === "" || relativeNormalizedKey === "/*") {
      return StringUtils.matchPattern(normalizedKey, endpoint);
    }
    else {
      // Matching endpoint with both protected resource and relative url of protected resource
      return StringUtils.matchPattern(normalizedKey, endpoint) || StringUtils.matchPattern(relativeNormalizedKey, endpoint);
    }
  });
}
/**
   * Finds scopes from first matching endpoint with HTTP method that matches request
   * @param protectedResourceMap Protected resource map
   * @param endpointArray Array of resources that match request endpoint
   * @param httpMethod Http method of the request
   * @returns
   */
matchScopesToEndpoint(protectedResourceMap: any, endpointArray: any[], httpMethod: string) {
  const allMatchedScopes: any[] = [];
  // Check each matched endpoint for matching HttpMethod and scopes
  endpointArray.forEach(matchedEndpoint => {
    const scopesForEndpoint: string[] = [];
    const methodAndScopesArray = protectedResourceMap.get(matchedEndpoint);
    // Return if resource is unprotected
    if (methodAndScopesArray === null) {
      allMatchedScopes.push(null);
      return;
    }
    methodAndScopesArray.forEach((entry:any) => {
      // Entry is either array of scopes or ProtectedResourceScopes object
      if (typeof entry === "string") {
        scopesForEndpoint.push(entry);
      }
      else {
        // Ensure methods being compared are normalized
        const normalizedRequestMethod = httpMethod.toLowerCase();
        const normalizedResourceMethod = entry.httpMethod.toLowerCase();
        // Method in protectedResourceMap matches request http method
        if (normalizedResourceMethod === normalizedRequestMethod) {
          entry.scopes.forEach((scope:any) => {
            scopesForEndpoint.push(scope);
          });
        }
      }
    });
    // Only add to all scopes if scopes for endpoint and method is found
    if (scopesForEndpoint.length > 0) {
      allMatchedScopes.push(scopesForEndpoint);
    }
  });
  if (allMatchedScopes.length > 0) {
    if (allMatchedScopes.length > 1) {
      this.authService.getLogger().warning("Interceptor - More than 1 matching scopes for endpoint found.");
    }
    // Returns scopes for first matching endpoint
    return allMatchedScopes[0];
  }
  return null;
}

idTokenExpired(expiryTime:number) {
  let exp = expiryTime * 1000;
  return (new Date(exp) <= new Date());
}

intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  if (this.msalInterceptorConfig.interactionType !== InteractionType.Popup && this.msalInterceptorConfig.interactionType !== InteractionType.Redirect) {
    throw new BrowserConfigurationAuthError("invalid_interaction_type", "Invalid interaction type provided to MSAL Interceptor. InteractionType.Popup, InteractionType.Redirect must be provided in the msalInterceptorConfiguration");
  }
  const scopes = this.getScopesForEndpoint(req.url, req.method);
  // If no scopes for endpoint, does not acquire token
  if (!scopes || scopes.length === 0) {
    this.authService.getLogger().verbose("Interceptor - no scopes for endpoint");
    return next.handle(req);
  }
  // Sets account as active account or first account
  let account:any;
  if (this.authService.instance.getActiveAccount()) {
    this.authService.getLogger().verbose("Interceptor - active account selected");
    account = this.authService.instance.getActiveAccount();
  }
  else {
    this.authService.getLogger().verbose("Interceptor - no active account, fallback to first account");
    account = this.authService.instance.getAllAccounts()[0];
  }
  const authRequest = typeof this.msalInterceptorConfig.authRequest === "function"
    ? this.msalInterceptorConfig.authRequest(this.authService, req, { account: account })
    : ({...this.msalInterceptorConfig.authRequest, account});
  this.authService.getLogger().info(`Interceptor - ${scopes.length} scopes found for endpoint`);
  this.authService.getLogger().infoPii(`Interceptor - [${scopes}] scopes found for ${req.url}`);
  this.authService.getLogger().info(`Interceptor - ${scopes.length} scopes found for endpoint`);
  this.authService.getLogger().infoPii(`Interceptor - [${scopes}] scopes found for ${req.url}`);
  let isForceRefresh = false;
  let idTokenExpiration = account[0]?.idTokenClaims['exp'];
  if (this.idTokenExpired(idTokenExpiration)) {
    isForceRefresh = true;
  }
  // Note: For MSA accounts, include openid scope when calling acquireTokenSilent to return idToken
  return this.authService.acquireTokenSilent({...authRequest, scopes, account, forceRefresh: isForceRefresh})
    .pipe(catchError(() => {
      this.authService.getLogger().error("Interceptor - acquireTokenSilent rejected with error. Invoking interaction to resolve.");
      return this.authService.acquireTokenPopup({...authRequest, scopes});
    }), switchMap((result) => {
      if (!result.idToken) {
        this.authService.getLogger().error("Interceptor - acquireTokenSilent resolved with null access token. Known issue with B2C tenants, invoking interaction to resolve.");
        return this.authService.acquireTokenPopup({...authRequest, scopes});
      }
      return of(result);
    }), switchMap((result) => {
      this.authService.getLogger().verbose("Interceptor - setting authorization headers");
      const headers = req.headers
        .set("Authorization", `Bearer ${result.idToken}`);
      const requestClone = req.clone({ headers });
      return next.handle(requestClone);
    }));
}
}
